function C({
  size: l = "16",
  fill: s = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M16.7167 21.7804C17.9568 21.7804 19.0435 20.9504 19.3698 19.754L21.2789 12.754C21.756 11.0046 20.439 9.28041 18.6258 9.28041H14.2523L15.2168 6.06548C15.4415 5.3163 15.284 4.50451 14.7954 3.89374C13.7588 2.59799 11.7479 2.73324 10.8942 4.15615L8.18375 8.67353C8.00001 8.97975 7.69655 9.18931 7.35344 9.25687C7.32915 9.26166 7.28 9.26907 7.28 9.26907V21.78C7.28 21.78 13.571 21.7804 16.7167 21.7804Z" fill="#2E2E38" />
<ns0:path d="M4.26 9.25H5.8V21.75H4.26C3.56412 21.75 3 21.1456 3 20.4V10.6C3 9.85442 3.56412 9.25 4.26 9.25Z" fill="#2E2E38" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (r) {
    const e = i.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", s), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", s), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { C as IconoirCustomThumbsUpSolid };