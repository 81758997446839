function h({
  size: l = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 15.5303C5.76256 15.8232 6.23744 15.8232 6.53033 15.5303L12 10.0607L17.4697 15.5303C17.7626 15.8232 18.2374 15.8232 18.5303 15.5303C18.8232 15.2374 18.8232 14.7626 18.5303 14.4697L12.5303 8.46967C12.2374 8.17678 11.7626 8.17678 11.4697 8.46967L5.46967 14.4697C5.17678 14.7626 5.17678 15.2374 5.46967 15.5303Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { h as IconoirNavArrowUp };