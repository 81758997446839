function u({
  size: l = "16",
  fill: s = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M5.75 5C5.75 4.58579 5.41421 4.25 5 4.25C4.58579 4.25 4.25 4.58579 4.25 5V19C4.25 19.4142 4.58579 19.75 5 19.75C5.41421 19.75 5.75 19.4142 5.75 19V12.75H12.25V19C12.25 19.4142 12.5858 19.75 13 19.75C13.4142 19.75 13.75 19.4142 13.75 19V5C13.75 4.58579 13.4142 4.25 13 4.25C12.5858 4.25 12.25 4.58579 12.25 5V11.25H5.75V5Z" fill="black" />
<ns0:path d="M16.5 12.6191L18.4 12.6191C19.1456 12.6191 19.75 13.2236 19.75 13.9691V15.652C19.75 16.3975 19.1456 17.002 18.4 17.002L17.25 17.002V18.252H19C19.4142 18.252 19.75 18.5877 19.75 19.002C19.75 19.4162 19.4142 19.752 19 19.752H17.1C16.3544 19.752 15.75 19.1475 15.75 18.402V16.852C15.75 16.1064 16.3544 15.502 17.1 15.502H18.25V14.1191L16.5 14.1191C16.0858 14.1191 15.75 13.7834 15.75 13.3691C15.75 12.9549 16.0858 12.6191 16.5 12.6191Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (r) {
    const e = i.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", s), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", s), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { u as IconoirCustomHeading2 };