import { Component } from '@angular/core';

@Component({
  selector: 'app-questions-agent',
  standalone: true,
  imports: [],
  templateUrl: './questions-agent.component.html',
  styleUrl: './questions-agent.component.scss'
})
export class QuestionsAgentComponent {

}
