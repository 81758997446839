function u({
  size: c = "16",
  fill: s = "currentColor",
  strokeWidth: l = "1",
  title: r = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString('<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><ns0:path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></ns0:svg>', "image/svg+xml"),
    t = i.documentElement;
  if (r) {
    const e = i.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = c;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", s), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", s), n.setAttribute("stroke-width", l), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { u as MotifNavigationIcMoreHoriz24px };