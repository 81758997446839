function C({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 20C5.25 19.5858 5.58579 19.25 6 19.25L18 19.25C18.4142 19.25 18.75 19.5858 18.75 20C18.75 20.4142 18.4142 20.75 18 20.75L6 20.75C5.58579 20.75 5.25 20.4142 5.25 20Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 16.5303C11.7626 16.8232 12.2374 16.8232 12.5303 16.5303L16.0303 13.0303C16.3232 12.7374 16.3232 12.2626 16.0303 11.9697C15.7374 11.6768 15.2626 11.6768 14.9697 11.9697L12.75 14.1893V4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4V14.1893L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L11.4697 16.5303Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (s) {
    const t = o.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", l), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { C as IconoirDownload };