function a({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L16.5303 15.5303C16.2374 15.8232 15.7626 15.8232 15.4697 15.5303C15.1768 15.2374 15.1768 14.7626 15.4697 14.4697L17.1893 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25H17.1893L15.4697 9.53033C15.1768 9.23744 15.1768 8.76256 15.4697 8.46967C15.7626 8.17678 16.2374 8.17678 16.5303 8.46967L19.5303 11.4697Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M7 3.75C6.30964 3.75 5.75 4.30964 5.75 5V19C5.75 19.6904 6.30964 20.25 7 20.25H17C17.6904 20.25 18.25 19.6904 18.25 19V18C18.25 17.5858 18.5858 17.25 19 17.25C19.4142 17.25 19.75 17.5858 19.75 18V19C19.75 20.5188 18.5188 21.75 17 21.75H7C5.48122 21.75 4.25 20.5188 4.25 19V5C4.25 3.48122 5.48122 2.25 7 2.25H17C18.5188 2.25 19.75 3.48122 19.75 5V6C19.75 6.41421 19.4142 6.75 19 6.75C18.5858 6.75 18.25 6.41421 18.25 6V5C18.25 4.30964 17.6904 3.75 17 3.75H7Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (s) {
    const t = o.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", l), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirLogOut };