<div class="footer">
  <div class="left-button-group">
    <div class="logo">
      <img class="ey-logo-beam-tag-stacked-rgb-e-icon" alt="" src="assets/img/ey_Logo.png">
    </div>
  </div>
  <div class="footer-text-content">
    <div class="footer-text-content1">
      <div class="text-link">
        <div class="text-link">
          <div class="text">Privacy Statement</div>
        </div>
      </div>
      <div class="subtext">
        <p class="eygm-limited">© 2024 EYGM Limited | All Rights Reserved.</p>
        <p class="content-within-this">Content within this application is confidential and meant for EY staff and EY clients only.</p>
      </div>
    </div>
  </div>
</div>
