import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AgentFiveTabComponent } from './agent-five-tab/agent-five-tab.component';
import { MotifIconModule } from '@ey-xd/ng-motif';
import { AgentBreadcrumbComponent } from '../components/agent-breadcrumb/agent-breadcrumb.component';
import { AgentFiveHomeComponent } from './agent-five-home/agent-five-home.component';

@Component({
  selector: 'app-agent-five',
  standalone: true,
  imports: [
    CommonModule, 
    AgentBreadcrumbComponent,
    AgentFiveHomeComponent, 
    AgentFiveTabComponent, 
    MotifIconModule,
  ],
  templateUrl: './agent-five.component.html',
  styleUrl: './agent-five.component.scss'
})
export class AgentFiveComponent {

  tabs: { tabName: string, tabNumberOrder: number, isActive: boolean }[] = [
    { tabName: 'Home', tabNumberOrder: 1, isActive: true }, 
    { tabName: 'FS Checks', tabNumberOrder: 2, isActive: false }, 
    { tabName: 'FS vs Comp', tabNumberOrder: 3, isActive: false }, 
    { tabName: 'P/Y vs C/Y Comp', tabNumberOrder: 4, isActive: false }, 
    { tabName: 'Comp checks', tabNumberOrder: 5, isActive: false }, 
  ];

  currentTab: { tabName: string, tabNumberOrder: number, isActive: boolean } = { tabName: 'Home', tabNumberOrder: 1, isActive: true };

  toggleTab(tab: { tabName: string, tabNumberOrder: number, isActive: boolean }){
    this.tabs.forEach(tb => tb.tabNumberOrder === tab.tabNumberOrder ? tb.isActive = true : tb.isActive = false);
    this.currentTab = tab;
  }

  toggleTabOnNumber($event: number){
    const tab:{ tabName: string, tabNumberOrder: number, isActive: boolean }| undefined = this.tabs.find(tb => tb.tabNumberOrder === $event);

    if(tab){
      this.toggleTab(tab);
    }
  }

}
