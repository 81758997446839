<div class="page-container">

    <div class="greet-container">
        <p class="user-header">Hello, {{ authenticationService.profile?.givenName }}</p>
        <p class="welcome-heading">Welcome to</p>
        <p class="heading-banner-text">The CIT Agent!</p>
    </div>

    <div class="cards-container">

        <motif-card orientation="vertical" cardVariant="card-border" class="card">
            <motif-card-header>
                <span class="motif-body2-default-regular">Agent 1 & 2</span>
                <div class="motif-card-subheader">
                    <span class="motif-h6-default-regular">Trial balance extraction and <br /> Chart of Account
                        Mapping</span>
                </div>
            </motif-card-header>
            <motif-card-body>
                <p>The Trial Balance extraction agent will minimise manual input in transforming our client’s raw
                    trial balance (TB) into the format that can be mapped to our tax sensitive structure by the next
                    agent.</p>
                <p>This Chart of Accounts mapping agent will map cleansed and extracted TB codes to the lowest level
                    of our tax sensitive structure based on its understanding of the account description.
                </p>
            </motif-card-body>
            <motif-card-footer>
                <button motifButton color="primary-alt" (click)="triggerAction()" size="fluid">
                    Get Started
                </button>
            </motif-card-footer>
        </motif-card>

        <motif-card orientation="vertical" cardVariant="card-border" class="card">
            <motif-card-header>
                <span class="motif-body2-default-regular">Agent 4</span>
                <div class="motif-card-subheader">
                    <span class="motif-h6-default-regular">Tax Questions Generation</span>
                </div>
            </motif-card-header>
            <motif-card-body>
                <p>This agent will generate questions for clients. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Proin nulla lorem, tempor nec nibh placerat, vulputate laoreet quam. Integer nisl risus,
                    pretium
                    in ligula sed, bibendum tempor purus. Suspendisse vel ultrices risus. Donec luctus facilisis
                    tristique.
                    Quisque et metus risus. Duis vulputate diam a risus ornare scelerisque.</p>

            </motif-card-body>
            <motif-card-footer>
                <button motifButton color="primary-alt" (click)="triggerAction()" size="fluid">
                    Get Started
                </button>
            </motif-card-footer>
        </motif-card>

        <motif-card orientation="vertical" cardVariant="card-border" class="card">

            <motif-card-header>
                <span class="motif-body2-default-regular">Agent 5</span>
                <div class="motif-card-subheader">
                    <span class="motif-h6-default-regular">AI Assisted Review</span>
                </div>
            </motif-card-header>
            <motif-card-body>
                <p>This agent will provide manager review package including:
                </p>
                <ul>
                    <li>Tax sensitive income statement with balance / adjustment audit trail to source data /
                        questions and prior period comparisons, and </li>
                    <li>Gen AI review of supporting documents (Report summary, Tax sensitive item focus (any
                            reorgs?) and checks (thin cap?), ties outs (PBT, CFwd to BFwd etc) </li>
                </ul>
            </motif-card-body>
            <motif-card-footer>
                <button motifButton color="primary-alt" (click)="goToAgentFive()" size="fluid">
                    Get Started
                </button>
            </motif-card-footer>
        </motif-card>

    </div>
</div>