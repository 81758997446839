function a({
  size: o = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: l = "",
  stroke: s = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12.5117 16.4414C12.8196 16.7185 12.8446 17.1927 12.5675 17.5006L12.5575 17.5117C12.2804 17.8196 11.8062 17.8446 11.4983 17.5675C11.1904 17.2904 11.1654 16.8162 11.4425 16.5083L11.4525 16.4972C11.7296 16.1893 12.2038 16.1643 12.5117 16.4414Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = i.documentElement;
  if (l) {
    const t = i.createElement("title");
    t.textContent = l, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = o;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", s), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", s);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirWarningCircle };