<div [ngClass]="classTheme">
    <motif-toast
        [autoHide]="autoHide"
        [type]="type"
        [show]="show"
        [actionText]="actionText"
        ariaLabelCloseButton="Close"
        (action)="action()"
        (hidden)="close()"
    >
        {{ message }}

        @if(content){
            <p class="content-text">{{ content }}</p>
        }
    </motif-toast>
</div>