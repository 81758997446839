<div class="breadcrumbs-container">

    <motif-icon [iconFunction]="IconoirHome" class="home-icon" (click)="navigateTo('home')" style="color: #656579;"></motif-icon>
    <motif-icon [iconFunction]="IconoirNavArrowRight"></motif-icon>
    
    @for (tab of breadcrumbTabs; track $index) {
        <p class="breadcrumb" (click)="navigateTo(tab.route)" [ngClass]="{ 'active': tab.active }">{{ tab.name }}</p>    
        <motif-icon [iconFunction]="IconoirNavArrowRight" *ngIf="($index + 1) !== breadcrumbTabs.length"></motif-icon>
    }

</div>