function h({
  size: l = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4.32515 21.7462C3.91299 21.705 3.61229 21.3375 3.6535 20.9253L4.2535 14.9253L5.20325 4.47772C5.26647 3.78236 5.84949 3.24994 6.54771 3.24994H20.3428C21.1366 3.24994 21.7591 3.93159 21.6872 4.72217L20.7963 14.5222C20.7331 15.2175 20.1501 15.7499 19.4519 15.7499H5.67852L5.14606 21.0746C5.10484 21.4867 4.73731 21.7874 4.32515 21.7462ZM5.82106 14.2499H19.3149L20.1785 4.74994H6.68469L5.82106 14.2499Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { h as IconoirWhiteFlag };