<motif-theme [variant]="theme"></motif-theme>
<ng-container *ngIf="{ value: authenticationService.subject | async }; let isLoggedIn">

  <!-- If logged in -->
  <ng-container *ngIf="isLoggedIn.value === true">
    <div class="contents">
      <app-header (changeThemeEvent)="toggleTheme()"
                  (toggleMenuEvent)="toggleMenu()"
      ></app-header>
      <!-- <header motif-header> CIT Agent </header> -->
      <nav motif-vertical-navigation [show]="showMenu" id="vertical-navigation-menu">

        <ul motif-vertical-navigation-menu position="top">
          @for (item of items; track item?.label) {
            <ng-container
              *ngTemplateOutlet="!item.subMenu || item.subMenu?.length === 0 ? itemTemplate : subMenuTemplate; context: {item, index: $index}">
            </ng-container>
          }

          <ng-template #itemTemplate let-item="item">
            <li motif-vertical-navigation-menu-item>
              <a motif-vertical-navigation-menu-link [routerLink]="item.routerLink" vertical-navigation-menu-item-active
                 [verticalNavigationMenuItemActiveOptions]="{exact: item.exact}">
                @if (item?.icon) {
                  <motif-icon [iconFunction]="item?.icon"></motif-icon><span>{{ item?.label }}</span>
                }
              </a>
            </li>
          </ng-template>

          <ng-template #subMenuTemplate let-item="item" let-index="index">
            <li motif-vertical-navigation-menu-item vertical-navigation-menu-item-active [useContextMenu]="false">
              <a motif-vertical-navigation-menu-link role="button" [attr.aria-controls]="'submenu-' + index" tabindex="0"
                 [hideArrow]="false">
                @if (item?.icon) {
                  <motif-icon [iconFunction]="item?.icon"></motif-icon>
                }
                <span>{{ item?.label }}</span>
              </a>
              <ul motif-vertical-navigation-sub-menu id="submenu-{{index}}">
                @for (subItem of item.subMenu; track subItem?.routerLink) {
                  <li motif-vertical-navigation-menu-item>
                    <a motif-vertical-navigation-menu-link [routerLink]="subItem.routerLink"
                       vertical-navigation-menu-item-active
                       [verticalNavigationMenuItemActiveOptions]="{exact: subItem.exact}">
                      <span>{{ subItem?.label }}</span>
                    </a>
                  </li>
                }
              </ul>
            </li>
          </ng-template>
        </ul>
      </nav>

      <main>
        <router-outlet></router-outlet>
      </main>

    </div>
  </ng-container>
  
  <!-- If not logged in -->
  <ng-container *ngIf="isLoggedIn.value === false">
    <router-outlet></router-outlet>
  </ng-container>
  
  <app-footer></app-footer>
</ng-container>
