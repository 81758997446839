import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconoirHome, IconoirNavArrowRight} from '@ey-xd/motif-icon';
import { MotifIconModule } from '@ey-xd/ng-motif';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-agent-breadcrumb',
  standalone: true,
  imports: [CommonModule, MotifIconModule],
  templateUrl: './agent-breadcrumb.component.html',
  styleUrl: './agent-breadcrumb.component.scss'
})
export class AgentBreadcrumbComponent {
  private destroy$ = new Subject<void>();
  
  breadcrumbTabs: any[] = [
    {
      name: "Agent 1",
      route: "/agent-one",
      active: false
    },
    {
      name: "Agent 2",
      route: "/agent-two",
      active: false
    },
    {
      name: "Agent 3",
      route: "/agent-three",
      active: false
    },
    {
      name: "Agent 4",
      route: "/agent-four",
      active: false
    },
    {
      name: "Agent 5",
      route: "/agent-five",
      active: false
    }
  ];

  IconoirHome = IconoirHome;
  IconoirNavArrowRight = IconoirNavArrowRight;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){}

  ngOnInit(){
    this.setActiveBreadcrumb();
  }

  setActiveBreadcrumb(){
    this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe(urlSegments => {
      const currentPath = '/' + urlSegments.map(segment => segment.path).join('/');
      this.breadcrumbTabs.forEach(tab => {
        tab.active = tab.route === currentPath;
      });
    });
  }

  navigateTo(route: string){
    this.router.navigate([route]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
