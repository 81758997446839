import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { MotifIconModule } from '@ey-xd/ng-motif';
import { IconoirCustomFlagSolid, IconoirWhiteFlag, IconoirCustomThumbsUpSolid, IconoirCustomThumbsDownSolid } from "@ey-xd/motif-icon";
import { AgentFiveService } from 'app/core/service/agent-five/agent-five.service';
import { take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'app/core/service/toast/toast.service';

@Component({
  selector: 'app-column',
  standalone: true,
  imports: [CommonModule, MotifIconModule],
  templateUrl: './column.component.html',
  styleUrl: './column.component.scss'
})
export class ColumnComponent {
  @Input() row: any = {};
  @Input() field!: string;
  @Input() type: string | undefined = 'text'; // Default type

  //icons
  IconoirCustomFlagSolid = IconoirCustomFlagSolid;
  IconoirWhiteFlag = IconoirWhiteFlag;
  IconoirCustomThumbsUpSolid = IconoirCustomThumbsUpSolid;
  IconoirCustomThumbsDownSolid = IconoirCustomThumbsDownSolid;

  reportPages: string [] = [];

  constructor(
    private agentFiveService: AgentFiveService,
    private toastService: ToastService
  ){}

  ngOnChanges(changes: SimpleChanges){
    if(changes && changes['row']){
      this.reportPages = this.mapPages(changes['row'].currentValue);
    }
  }

  mapPages(row: any): string[]{
    const toret: string[] = [];

    if(row['page']){
      const pages = row['page'];
      const filename: string = this.getFileName(row.filename);

      if (pages.length > 1) {
        pages.map((page: number) => {
          toret.push(`P.${page}`);
        });
        toret.push(`of ${filename}`)
      } else {
        // Handle single page
        toret.push(`P.${pages[0]}`)
        toret.push(`of ${filename}`)
      }
    }
    return toret;
  }

  isLastPage(index: number) {
    return index === this.reportPages.length - 1;
  }

  goToPage(page: string, fileId: string){
    const p: number = Number(page.split('.')[1]);

    this.agentFiveService.getFSCheckPDFPage(fileId)
    .pipe(take(1))
    .subscribe({
      next: (data: Blob) => {
        const blobUrl = URL.createObjectURL(data);
        window.open(`${blobUrl}#page=${p}`, '_blank');
      }, 
      error: (error: HttpErrorResponse) => {
        const message : string = error.error?.message || 'An unexpected error occurred';
        this.toastService.showToast("error", `${message}`);
      } 
    });
  }


  // Returns file name without the extension of the file
  private getFileName(fileName: string): string{
    const lastDotIndex = fileName.lastIndexOf(".");
    
    // If there's no dot or it's the first character, return the file name as-is
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        return fileName;
    }
    
    // Return the file name without the extension
    return fileName.substring(0, lastDotIndex);
  }
}
