import {Inject, Injectable, OnDestroy} from '@angular/core';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {AccountInfo, AuthenticationResult, InteractionType, PopupRequest, RedirectRequest} from '@azure/msal-browser';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {IProfileType, ProfileType} from './profile';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  loggedIn = false;
  profile?: ProfileType;
  userAccountByHomeAccountId?: AccountInfo | null;
  logoutHint: string | undefined;
  private profileRequested = false;
  subject = new BehaviorSubject<boolean>(false);
  readonly account$: BehaviorSubject<AccountInfo | null> = new BehaviorSubject<AccountInfo | null>(null);
  private readonly _destroying$ = new Subject<void>();
  private readonly _defaultScopes = {
    scopes: ['user.read', environment.apiConfig.scopes],
  };
  private GRAPH_ENDPOINT: string = 'https://graph.microsoft.com/v1.0/me';

  private tokenSubject = new BehaviorSubject<string | null>(null); // Holds the current token
  private refreshTimer: any; // Timer for token refresh
  private isRefreshing = false; // Prevents multiple refreshes

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  checkAccount(newActiveAccount?: AccountInfo): void {
    let account = this.msalService.instance.getActiveAccount();
    if (newActiveAccount && account?.username !== newActiveAccount.username) {
      console.log('Setting new active account', newActiveAccount.username);
      this.msalService.instance.setActiveAccount(newActiveAccount);
      account = newActiveAccount;
    }
    if (!account) {
      const allAccounts = this.msalService.instance.getAllAccounts();
      if (allAccounts.length > 0) {
        if (allAccounts.length > 1) {
          console.log(
            'Multiple accounts found when trying to set active account',
            allAccounts.map((x) => x.username),
          );
        }
        console.log(
          'Setting active account to first account found',
          allAccounts[0].username,
          allAccounts.map((x) => x.username),
        );
        this.msalService.instance.setActiveAccount(allAccounts[0]);
        account = this.msalService.instance.getActiveAccount();
      } else {
        console.log(
          'No accounts found when trying to set active account',
          allAccounts.map((x) => x.username),
        );
      }
    }
    const loggedIn = account != null;
    if (this.subject.value !== loggedIn) {
      this.setIsLoggedIn(loggedIn);
      this.account$.next(account);
    }
    if (this.loggedIn && !this.profileRequested && !this.profile) {
      this.profileRequested = true;
      if(account) {
        this.profile = new ProfileType(account.name ?? '', account.name ?? '', account.username);
      }
      this.userAccountByHomeAccountId = this.msalService.instance.getAccountByHomeId(account?.homeAccountId ?? '');
      this.logoutHint = this.userAccountByHomeAccountId?.idTokenClaims?.login_hint;
    }
  }

  getProfile(): Observable<ProfileType> {
    return this.httpClient.get(this.GRAPH_ENDPOINT).pipe(
      map((profile) => {
        console.log('Profile:', profile);
        const profileType = profile as IProfileType;
        return new ProfileType(profileType.displayName ?? '', profileType.givenName ?? '', profileType.userPrincipalName ?? '');
      }),
    );
  }

  login(): void {
    const loginRequest = this.msalGuardConfig.authRequest || { scopes: ['user.read'] };
  
    this.msalService.loginPopup(loginRequest as PopupRequest);

    // Start monitoring the token after login
    this.msalService.instance.handleRedirectPromise()
      .then((response) => {
        if(response){
          console.log('Login successful, tokens acquired: ', response);
          this.initializeTokenManagement();
          this.router.navigate(['home']);
        } else {
          console.log('No login response received');
        }
      })
      .catch(error => {
        // to show errors in case any function after this one throws something
        console.error('Error during handleRedirectPromise: ', error);
      });
  }


  logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }
  logoutPromptless(): void {
    this.msalService.logoutRedirect({ account: this.userAccountByHomeAccountId });
  }

  isLoggedIn(): Observable<boolean> {
    return this.subject.asObservable();
  }

  setIsLoggedIn(value: boolean): void {
    this.loggedIn = value;
    this.subject.next(value);
  }

  clearStorage(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  isUserLoggedIn(): boolean {
    if (!this.loggedIn) {
      this.checkAccount();
    }
    return this.loggedIn;
  }

  /** Token management functions */

  initializeTokenManagement(): void {
    const account = this.msalService.instance.getActiveAccount();

    if (!account) {
      throw new Error('No active account. User is not logged in.');
    }

    const token = account.idToken;
    if (token) {
      this.setToken(token); // Set and start tracking the token
    } else {
      throw new Error('No token available for the active account.');
    }
  }

  private setToken(token: string): void {
    this.tokenSubject.next(token); // Update the token
    this.scheduleTokenRefresh(token); // Schedule automatic refresh
  }

  private scheduleTokenRefresh(token: string): void {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer); // Clear previous timer
    }

    const payload = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
    const currentTime = Math.floor(Date.now() / 1000);
    const expiresIn = payload.exp - currentTime;

    const refreshBuffer = 5 * 60; // Refresh 5 minutes before expiration
    const refreshTime = (expiresIn - refreshBuffer) * 1000;
    
    if (refreshTime > 0) {
      this.refreshTimer = setTimeout(() => this.refreshToken(), refreshTime);
    } else {
      console.warn('Token is already expired. Refreshing immediately.');
      this.refreshToken();
    }
  }

  private refreshToken(): void {
    if (this.isRefreshing) {
      console.log('Refresh in progress. Skipping additional refresh.');
      return; // Exit if a refresh is already happening
    }

    this.isRefreshing = true;

    const account = this.msalService.instance.getActiveAccount();

    if (!account) {
      console.error('No active account. User is not logged in.');
      this.isRefreshing = false;
      return;
    }

    this.msalService.acquireTokenSilent({
      account: account,
      scopes: environment.apiConfig.scopes,
      forceRefresh: true,
    }).subscribe({
      next: (result) => {
        if (result.idToken) {
          this.setToken(result.idToken); // Update token and reschedule refresh
          console.log("Token refreshed successfully")
        }
      },
      error: (error) => {
        console.error('Token refresh failed:', error);
        this.isRefreshing = false;
      },
      complete: () => {
        this.isRefreshing = false; // Allow new refreshes
      },
    });
  }

  getToken(): string | null {
    return this.tokenSubject.getValue(); // Return the current token
  }
  
  /** End Token management functions */

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();

    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  }
}
