function g({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: l = "1",
  title: C = "",
  stroke: s = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 5.6C7.25 4.85442 7.85442 4.25 8.6 4.25H12C12.0383 4.25 13.012 4.25082 14.0021 4.74585C14.5077 4.99868 15.0362 5.38968 15.4365 5.99023C15.84 6.59548 16.0833 7.36877 16.0833 8.33333C16.0833 9.2979 15.84 10.0712 15.4365 10.6764C15.1869 11.0509 14.8874 11.344 14.5747 11.5728C15.0761 11.8396 15.5817 12.2226 15.9816 12.7724C16.4572 13.4265 16.75 14.2713 16.75 15.3333C16.75 16.3954 16.4572 17.2402 15.9816 17.8943C15.5117 18.5403 14.896 18.956 14.3125 19.2235C13.7303 19.4903 13.1581 19.62 12.7383 19.6841C12.5265 19.7165 12.3485 19.7329 12.2209 19.7412C12.1569 19.7454 12.1052 19.7476 12.0678 19.7488C12.0491 19.7493 12.0339 19.7496 12.0226 19.7498L12.0084 19.75L12.0034 19.75L12.0015 19.75C12.0012 19.75 12 19.75 12 19V19.75H8.6C7.85442 19.75 7.25 19.1456 7.25 18.4V5.6ZM11.9994 12.4167L12.0004 12.4167L12.022 12.4172C12.0432 12.4179 12.0775 12.4193 12.1229 12.4222C12.214 12.4282 12.3485 12.4404 12.5117 12.4654C12.8419 12.5158 13.2697 12.6153 13.6875 12.8068C14.104 12.9977 14.4883 13.2694 14.7684 13.6547C15.0428 14.0319 15.25 14.562 15.25 15.3333C15.25 16.1046 15.0428 16.6348 14.7684 17.012C14.4883 17.3972 14.104 17.669 13.6875 17.8599C13.2697 18.0514 12.8419 18.1509 12.5117 18.2013C12.3485 18.2262 12.214 18.2385 12.1229 18.2444C12.0775 18.2474 12.0432 18.2488 12.022 18.2495L12.0004 18.25L11.9988 18.25L8.75 18.25V12.4167H11.9979M8.75 10.9167H11.9972C11.9971 10.9167 11.9973 10.9167 11.9972 10.9167L12.0124 10.9163C12.028 10.9157 12.0543 10.9146 12.0898 10.9121C12.161 10.907 12.2676 10.8964 12.3975 10.8748C12.6609 10.8309 13.0006 10.7445 13.3313 10.5792C13.659 10.4153 13.9638 10.1813 14.1885 9.84439C14.41 9.51213 14.5833 9.03543 14.5833 8.33333C14.5833 7.63124 14.41 7.15454 14.1885 6.82228C13.9638 6.48533 13.659 6.25134 13.3313 6.08749C12.6726 5.75817 11.9985 5.7502 11.9985 5.75C11.998 5.75 11.9985 5.75 11.9985 5.75L8.75 5.75V10.9167Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = o.documentElement;
  if (C) {
    const e = o.createElement("title");
    e.textContent = C, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = i;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", s), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", l), n.setAttribute("stroke", s);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { g as IconoirBold };