import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconoirCustomFlagSolid, IconoirWhiteFlag, IconoirXmark } from "@ey-xd/motif-icon";
import { MotifIconModule } from '@ey-xd/ng-motif';

@Component({
  selector: 'app-analysis-warning',
  standalone: true,
  imports: [MotifIconModule],
  templateUrl: './analysis-warning.component.html',
  styleUrl: './analysis-warning.component.scss'
})
export class AnalysisWarningComponent {
  @Input() flaggedItems: any[] = [];
  @Input() fullData: any[] = [];
  
  @Output() closeEmitter = new EventEmitter<any>();

  IconoirCustomFlagSolid = IconoirCustomFlagSolid;
  IconoirWhiteFlag = IconoirWhiteFlag;
  IconoirXmark = IconoirXmark;

  close(){
    this.closeEmitter.emit()
  }
}
