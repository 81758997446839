function u({
  size: i = "16",
  fill: s = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M4 6.75C3.58579 6.75 3.25 6.41421 3.25 6C3.25 5.58579 3.58579 5.25 4 5.25H20C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75H4Z" fill="black" />
<ns0:path d="M9 11.25C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H20C20.4142 12.75 20.75 12.4142 20.75 12C20.75 11.5858 20.4142 11.25 20 11.25H9Z" fill="black" />
<ns0:path d="M3.25 18C3.25 18.4142 3.58579 18.75 4 18.75H20C20.4142 18.75 20.75 18.4142 20.75 18C20.75 17.5858 20.4142 17.25 20 17.25H4C3.58579 17.25 3.25 17.5858 3.25 18Z" fill="black" />
<ns0:path d="M3.5 13.4697C3.20711 13.7626 3.20711 14.2374 3.5 14.5303C3.79289 14.8232 4.26777 14.8232 4.56066 14.5303L6.56066 12.5303C6.85355 12.2374 6.85355 11.7626 6.56066 11.4697L4.56066 9.46967C4.26777 9.17678 3.79289 9.17678 3.5 9.46967C3.20711 9.76256 3.20711 10.2374 3.5 10.5303L4.96967 12L3.5 13.4697Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = o.documentElement;
  if (r) {
    const e = o.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = i;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", s), t.setAttribute("stroke", l), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", s), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { u as IconoirCustomIndentIncrease };