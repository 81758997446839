function u({
  size: o = "16",
  fill: l = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: s = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M20.2502 20.25V3.75H3.75019V20.25H20.2502ZM21.7502 20.4C21.7502 21.1456 21.1458 21.75 20.4002 21.75H3.60019C2.85461 21.75 2.25019 21.1456 2.25019 20.4V3.6C2.25019 2.85441 2.85461 2.25 3.60019 2.25H20.4002C21.1458 2.25 21.7502 2.85442 21.7502 3.6V20.4Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M9.70475 12.3106C9.89862 12.2276 10.1185 12.2299 10.3105 12.3172L21.3105 17.3172C21.6876 17.4886 21.8544 17.9333 21.683 18.3103C21.5116 18.6874 21.0669 18.8542 20.6898 18.6828L9.99128 13.8198L3.29563 16.6894C2.91491 16.8525 2.474 16.6762 2.31083 16.2954C2.14767 15.9147 2.32403 15.4738 2.70475 15.3106L9.70475 12.3106Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M16.0002 6.75C16.6905 6.75 17.2502 7.30964 17.2502 8C17.2502 8.69036 16.6905 9.25 16.0002 9.25C15.3098 9.25 14.7502 8.69036 14.7502 8C14.7502 7.30964 15.3098 6.75 16.0002 6.75ZM18.7502 8C18.7502 6.48122 17.519 5.25 16.0002 5.25C14.4814 5.25 13.2502 6.48122 13.2502 8C13.2502 9.51878 14.4814 10.75 16.0002 10.75C17.519 10.75 18.7502 9.51878 18.7502 8Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = i.documentElement;
  if (r) {
    const t = i.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = o;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", l), e.setAttribute("stroke", s), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", l), n.setAttribute("stroke-width", c), n.setAttribute("stroke", s);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { u as IconoirMediaImage };