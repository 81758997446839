function g({
  size: l = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M5.55004 7.34926C5.88142 7.10073 6.35152 7.16789 6.60004 7.49926L9.00004 10.6993L11.4 7.49926C11.6486 7.16789 12.1187 7.10073 12.45 7.34926C12.7814 7.59779 12.8486 8.06789 12.6 8.39926L9.93754 11.9493L12.6 15.4993C12.8486 15.8306 12.7814 16.3007 12.45 16.5493C12.1187 16.7978 11.6486 16.7306 11.4 16.3993L9.00004 13.1993L6.60004 16.3993C6.35152 16.7306 5.88142 16.7978 5.55004 16.5493C5.21867 16.3007 5.15152 15.8306 5.40004 15.4993L8.06254 11.9493L5.40004 8.39926C5.15152 8.06789 5.21867 7.59779 5.55004 7.34926Z" fill="black" />
<ns0:path d="M15.5 12.8711H17.4C18.1456 12.8711 18.75 13.4755 18.75 14.2211V15.9039C18.75 16.6495 18.1456 17.2539 17.4 17.2539L16.25 17.2539V18.5039H18C18.4142 18.5039 18.75 18.8397 18.75 19.2539C18.75 19.6681 18.4142 20.0039 18 20.0039H16.1C15.3544 20.0039 14.75 19.3995 14.75 18.6539V17.1039C14.75 16.3583 15.3544 15.7539 16.1 15.7539H17.25V14.3711L15.5 14.3711C15.0858 14.3711 14.75 14.0353 14.75 13.6211C14.75 13.2069 15.0858 12.8711 15.5 12.8711Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { g as IconoirCustomSuperscript };