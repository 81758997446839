function d({
  size: i = "16",
  fill: C = "currentColor",
  strokeWidth: l = "1",
  title: r = "",
  stroke: s = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M7.39312 4.63587C6.76553 5.68186 6.75 6.97236 6.75 8C6.75 8.41421 6.41421 8.75 6 8.75C5.28668 8.75 4.20002 8.97016 3.31323 9.60865C2.46468 10.2196 1.75 11.2444 1.75 13C1.75 14.1955 2.08156 15.0384 2.53739 15.6393C2.99923 16.2481 3.62419 16.6545 4.27355 16.9089C4.65923 17.06 4.84941 17.4951 4.69833 17.8808C4.54726 18.2665 4.11213 18.4567 3.72645 18.3056C2.88209 17.9748 2.00705 17.4221 1.34232 16.5458C0.67158 15.6616 0.25 14.4934 0.25 13C0.25 10.7556 1.20198 9.2804 2.43677 8.39135C3.35065 7.73336 4.39099 7.41468 5.25627 7.30083C5.28248 6.32248 5.41727 5.01348 6.10688 3.86413C7.02939 2.32662 8.81736 1.25 12 1.25C15.1826 1.25 16.9706 2.32662 17.8931 3.86413C18.5827 5.01348 18.7175 6.32248 18.7437 7.30083C19.609 7.41468 20.6493 7.73336 21.5632 8.39135C22.798 9.2804 23.75 10.7556 23.75 13C23.75 14.4934 23.3284 15.6616 22.6577 16.5458C21.993 17.4221 21.1179 17.9748 20.2736 18.3056C19.8879 18.4567 19.4527 18.2665 19.3017 17.8808C19.1506 17.4951 19.3408 17.06 19.7264 16.9089C20.3758 16.6545 21.0008 16.2481 21.4626 15.6393C21.9184 15.0384 22.25 14.1955 22.25 13C22.25 11.2444 21.5353 10.2196 20.6868 9.60865C19.8 8.97016 18.7133 8.75 18 8.75C17.5858 8.75 17.25 8.41421 17.25 8C17.25 6.97236 17.2345 5.68186 16.6069 4.63587C16.0294 3.67338 14.8174 2.75 12 2.75C9.18264 2.75 7.97061 3.67338 7.39312 4.63587ZM7.96967 15.9697L11.4697 12.4697C11.7626 12.1768 12.2374 12.1768 12.5303 12.4697L16.0303 15.9697C16.3232 16.2626 16.3232 16.7374 16.0303 17.0303C15.7374 17.3232 15.2626 17.3232 14.9697 17.0303L12.75 14.8107V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V14.8107L9.03033 17.0303C8.73744 17.3232 8.26256 17.3232 7.96967 17.0303C7.67678 16.7374 7.67678 16.2626 7.96967 15.9697Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = o.documentElement;
  if (r) {
    const e = o.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = i;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", C), t.setAttribute("stroke", s), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", C), n.setAttribute("stroke-width", l), n.setAttribute("stroke", s);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { d as IconoirCloudUpload };