function a({
  size: i = "16",
  fill: l = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: s = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M13.7022 5.27797C14.1011 5.38965 14.3339 5.80354 14.2222 6.20241L10.7222 18.7024C10.6105 19.1013 10.1967 19.3341 9.79778 19.2224C9.39891 19.1107 9.16609 18.6968 9.27778 18.298L12.7778 5.79797C12.8895 5.3991 13.3034 5.16628 13.7022 5.27797Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.46967 12.5305C2.17678 12.2376 2.17678 11.7628 2.46967 11.4699L5.96967 7.96986C6.26256 7.67697 6.73744 7.67697 7.03033 7.96986C7.32322 8.26275 7.32322 8.73763 7.03033 9.03052L4.06066 12.0002L7.03033 14.9699C7.32322 15.2628 7.32322 15.7376 7.03033 16.0305C6.73744 16.3234 6.26256 16.3234 5.96967 16.0305L2.46967 12.5305Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M21.5303 12.5305C21.8232 12.2376 21.8232 11.7628 21.5303 11.4699L18.0303 7.96986C17.7374 7.67697 17.2626 7.67697 16.9697 7.96986C16.6768 8.26275 16.6768 8.73763 16.9697 9.03052L19.9393 12.0002L16.9697 14.9699C16.6768 15.2628 16.6768 15.7376 16.9697 16.0305C17.2626 16.3234 17.7374 16.3234 18.0303 16.0305L21.5303 12.5305Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (r) {
    const t = o.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", l), e.setAttribute("stroke", s), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", l), n.setAttribute("stroke-width", c), n.setAttribute("stroke", s);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirCode };