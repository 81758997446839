import { Injectable } from '@angular/core';
import { ApiService } from 'app/core/service/api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgentFiveService {

  // This api version is needed for agent 5 endpoints
  private readonly apiVersion: string = `v1/ad/`;
  private readonly coreVersion: string = `v1/core/`;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * FS Checks tab functions
   */

  postDocumentToFSChecks(file: File): Observable<any>{
    const slug: string = `${this.apiVersion}` + `fscheck`;
    const formData = new FormData();
    formData.append('file', file);

    return this.apiService.post(slug, formData, '');
  }

  getFSCheckLatestId(): Observable<any>{
    const slug: string = `${this.apiVersion}` + `fscheck`;
    return this.apiService.get(slug, { limit: 1 }, '');
  }

  getFSChecksResult(projectId: string): Observable<any> {
    const slug: string = `${this.apiVersion}` + `fscheck/${projectId}/result`; 
    return this.apiService.get(slug, {}, '');
  }

  getFSChecksStatus(projectId: string | any): Observable<any> {
    const slug: string = `${this.apiVersion}` + `fscheck/${projectId}/status`; 
    return this.apiService.get(slug, {}, '');
  }
  
  getFSCheckExported(projectId: string | any): Observable<any> {
    const slug: string = `${this.apiVersion}` + `fscheck/${projectId}/export`; 
    return this.apiService.get(slug, {}, '', { responseType: 'blob' });
  }
  
  getFSCheckPDFPage(fileId: string | any): Observable<any> {
    const slug: string = `${this.coreVersion}` + `download/${fileId}`;
    return this.apiService.get(slug, {}, '', { responseType: 'blob' });
  }



  /**
   * FS vs Comp tab functions
   */

  postDocumentsToFSvsComp(files: File[]){
    const slug: string = `${this.apiVersion}` + `fscomp`;
    const formData = new FormData();
    formData.append('file-1', files[0]);
    formData.append('file-2', files[1]);

    return this.apiService.post(slug, formData, '');
  }

  getFSvsCompLatestId(): Observable<any>{
    const slug: string = `${this.apiVersion}` + `fscomp`;
    return this.apiService.get(slug, { limit: 1 }, '');
  }

  getFSvsCompResult(projectId: string): Observable<any> {
    const slug: string = `${this.apiVersion}` + `fscomp/${projectId}/result`; 
    return this.apiService.get(slug, {}, '');
  }

  getFSvsCompStatus(projectId: string | any): Observable<any> {
    const slug: string = `${this.apiVersion}` + `fscomp/${projectId}/status`; 
    return this.apiService.get(slug, {}, '');
  }


}
