function h({
  size: l = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4.46978 12.4697C4.76268 12.1768 5.23755 12.1768 5.53044 12.4697L9.00011 15.9393L18.4698 6.46967C18.7627 6.17678 19.2375 6.17678 19.5304 6.46967C19.8233 6.76256 19.8233 7.23744 19.5304 7.53033L9.53044 17.5303C9.23755 17.8232 8.76268 17.8232 8.46978 17.5303L4.46978 13.5303C4.17689 13.2374 4.17689 12.7626 4.46978 12.4697Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { h as IconoirCheck };