function a({
  size: s = "16",
  fill: n = "currentColor",
  strokeWidth: d = "1",
  title: r = "",
  stroke: i = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 3C2.25 2.58579 2.58579 2.25 3 2.25H21C21.4142 2.25 21.75 2.58579 21.75 3V21C21.75 21.4142 21.4142 21.75 21 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21V3ZM3.75 3.75V20.25H20.25V3.75H3.75Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 16.5C2.25 16.0858 2.58579 15.75 3 15.75H21C21.4142 15.75 21.75 16.0858 21.75 16.5C21.75 16.9142 21.4142 17.25 21 17.25H3C2.58579 17.25 2.25 16.9142 2.25 16.5Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 7.5C2.25 7.08579 2.58579 6.75 3 6.75H21C21.4142 6.75 21.75 7.08579 21.75 7.5C21.75 7.91421 21.4142 8.25 21 8.25H3C2.58579 8.25 2.25 7.91421 2.25 7.5Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 2.25C16.9142 2.25 17.25 2.58579 17.25 3V21C17.25 21.4142 16.9142 21.75 16.5 21.75C16.0858 21.75 15.75 21.4142 15.75 21V3C15.75 2.58579 16.0858 2.25 16.5 2.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V21C12.75 21.4142 12.4142 21.75 12 21.75C11.5858 21.75 11.25 21.4142 11.25 21V3C11.25 2.58579 11.5858 2.25 12 2.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.91421 2.25 8.25 2.58579 8.25 3V21C8.25 21.4142 7.91421 21.75 7.5 21.75C7.08579 21.75 6.75 21.4142 6.75 21V3C6.75 2.58579 7.08579 2.25 7.5 2.25Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (r) {
    const t = o.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = s;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", n), e.setAttribute("stroke", i), e.querySelectorAll("path, line, rect, circle").forEach(l => {
      l.setAttribute("fill", n), l.setAttribute("stroke-width", d), l.setAttribute("stroke", i);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirTable };