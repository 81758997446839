function u({
  size: i = "16",
  fill: s = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M20 6.75C20.4142 6.75 20.75 6.41421 20.75 6C20.75 5.58579 20.4142 5.25 20 5.25H4C3.58579 5.25 3.25 5.58579 3.25 6C3.25 6.41421 3.58579 6.75 4 6.75H20Z" fill="black" />
<ns0:path d="M20 12.75C20.4142 12.75 20.75 12.4142 20.75 12C20.75 11.5858 20.4142 11.25 20 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H20Z" fill="black" />
<ns0:path d="M20.75 18C20.75 18.4142 20.4142 18.75 20 18.75H4C3.58579 18.75 3.25 18.4142 3.25 18C3.25 17.5858 3.58579 17.25 4 17.25H20C20.4142 17.25 20.75 17.5858 20.75 18Z" fill="black" />
<ns0:path d="M6.53033 10.5303C6.82322 10.2374 6.82322 9.76256 6.53033 9.46967C6.23744 9.17678 5.76256 9.17678 5.46967 9.46967L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303L5.46967 14.5303C5.76256 14.8232 6.23744 14.8232 6.53033 14.5303C6.82322 14.2374 6.82322 13.7626 6.53033 13.4697L5.06066 12L6.53033 10.5303Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = o.documentElement;
  if (r) {
    const e = o.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = i;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", s), t.setAttribute("stroke", l), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", s), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { u as IconoirCustomIndentDecrease };