function h({
  size: l = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H6C5.58579 12.75 5.25 12.4142 5.25 12Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { h as IconoirMinus };