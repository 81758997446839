import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableComponent } from 'app/core/components/table-component/table.component';
import { MotifButtonModule, MotifProgressBarModule } from  '@ey-xd/ng-motif';
import { AnalysisWarningComponent } from '../analysis-warning/analysis-warning.component';
import { AgentFiveService } from 'app/core/service/agent-five/agent-five.service';
import { Subject, switchMap, takeUntil, tap, filter, catchError, EMPTY, take } from 'rxjs';
import { ToastService } from 'app/core/service/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-agent-five-tab',
  standalone: true,
  imports: [TableComponent, AnalysisWarningComponent, MotifButtonModule, MotifProgressBarModule],
  templateUrl: './agent-five-tab.component.html',
  styleUrl: './agent-five-tab.component.scss'
})
export class AgentFiveTabComponent {
  @Output() tabNumberEmitter = new EventEmitter<number>();
  
  private reportId: string = '';
  private destroy$ = new Subject<void>();
  
  private _tabNumber!: number;
  pageMessage: string = '';

  @Input() 
  set tabNumber(value: number) {
    this._tabNumber = value;
    this.initialize();
  }

  get tabNumber(): number {
    return this._tabNumber;
  }

  tableData: any[] = [];
  
  //TODO: define this and apply parameters in setTableColumnConfiguration() 
  // function once a model file can be defined based on data

  tableColumns: any[]= [
    { header: "#", field: "order", type: 'flagged-number', class: "small-column"},
    { header: "Name", field: "name", type: 'text', class: "medium-column" },
    { header: "Description", field: "description", type: 'text', class: "medium-column" },
    { header: "AI Analysis", field: "reason", type: 'text-quoting-document', class: "medium-column" },
  ];

  flaggedData: any[] = [];
  passedTestData: any[] = [];

  loading: boolean = true;
  contentAvailable: boolean = false; // Variable to track content availability
  showWarning: boolean = false;
  showOnlyFlaggedData: boolean = false;

  exportingLoader: boolean = false;

  constructor(
    private agentFiveService: AgentFiveService,
    private toastService: ToastService
  ){}

  ngOnInit() {}

  initialize(){
    this.restartVariables();

    switch(this.tabNumber){
      // case 1: is for home, not needed
      case 2: // FS Check
        this.agentFiveService.getFSCheckLatestId().pipe(
          tap((data: any) => this.reportId = this.getLatestProjectId(data)),
          switchMap(() => 
            this.agentFiveService.getFSChecksResult(this.reportId).pipe(
              tap((data: any) => {
                // Check if result is ready before proceeding
                const contentAvailable = this.resultIsReady(data);
                if (!contentAvailable) {
                  this.loading = false; 
                  this.contentAvailable = false;
                  this.pageMessage = "The result is not available yet. Please come back in a few minutes after the proccess is finished."
                } else {
                  this.contentAvailable = true;
                }
              }),
              filter(() => this.contentAvailable), // Only continue if content is available
              tap((data:any) => this.tableData = this.getTableData(data)),
              tap(_ => this.categorizeRows()),
              tap(_ => this.loading = false),
              // catch error for inner call
              catchError((err: HttpErrorResponse) => {                
                const message: string = err.error.message;

                this.loading = false;
                this.contentAvailable = false; // Indicate that content is unavailable
                this.pageMessage = "There is no result available here because the process failed. Please try running your documents again."
                this.toastService.showToast('error', `${message}`);
                return EMPTY;
              })
            )
          ),
          takeUntil(this.destroy$), // Handle unsubscription
          // Case where there is no report or there is no processing report and the user gets there
          catchError((err: HttpErrorResponse) => { 
            this.loading = false;
            this.contentAvailable = false;
            this.pageMessage = "There is no data in this page. Please run the check first to be able to see your report.";
            return EMPTY;
          })
        ).subscribe();
        break;
      case 3: // FS vs Comp
        this.agentFiveService.getFSvsCompLatestId().pipe(
          tap((data: any) => this.reportId = this.getLatestProjectId(data)),
          switchMap(() => 
            this.agentFiveService.getFSvsCompResult(this.reportId).pipe(
              tap((data: any) => {
                // Check if result is ready before proceeding
                const contentAvailable = this.resultIsReady(data);
                if (!contentAvailable) {
                  this.loading = false; 
                  this.contentAvailable = false;
                  this.pageMessage = "The result is not available yet. Please come back in a few minutes after the proccess is finished."
                } else {
                  this.contentAvailable = true;
                }
              }),
              filter(() => this.contentAvailable), // Only continue if content is available
              tap((data:any) => this.tableData = this.getTableData(data)),
              tap(_ => this.categorizeRows()),
              tap(_ => this.loading = false),
              // catch error for inner call
              catchError((err: HttpErrorResponse) => {
                const message: string = err.error.message;

                this.loading = false;
                this.contentAvailable = false; // Indicate that content is unavailable
                this.pageMessage = "There is no result available here because the process failed. Please try running your documents again."
                this.toastService.showToast('error', `${message}`);
                return EMPTY;
              })
            )
          ),
          takeUntil(this.destroy$), // Handle unsubscription
          // Case where there is no report or there is no processing report and the user gets there
          catchError((err: HttpErrorResponse) => { 
            this.loading = false;
            this.contentAvailable = false;
            this.pageMessage = "There is no data in this page. Please run the check first to be able to see your report.";
            return EMPTY;
          })
        ).subscribe();
        break;
      case 4: // P/Y vs C/Y Comp
        this.loading = false;
        this.contentAvailable = false;
        this.pageMessage = "There is no data in this page. Please run the check first to be able to see your report.";
        break;
      case 5: // Comp checks
        this.loading = false;
        this.contentAvailable = false;
        this.pageMessage = "There is no data in this page. Please run the check first to be able to see your report.";
        break;
    }
  }

  private restartVariables(){
    this.tableData = [];
    this.flaggedData = [];
    this.passedTestData = [];
    this.reportId = '';
    this.loading = true;
    this.contentAvailable = false;
    this.showWarning = false;
    this.showOnlyFlaggedData = false;
  }

  private resultIsReady(data: any): boolean{
    return data.items[0].processes[0].q_result?.length > 0;
  }

  private getTableData(data: any): any[]{
    const toret: any[] = data.items[0]?.processes[0]?.q_result || [];
    const reportFileName: string = data.items[0]?.processes[0]?.files[0]?.filename || '';

    const reportFileId: string = this.getReportFileId(data.items[0]?.processes[0]?.files) || '';
    
    //TODO: When we are in other tabs this must be studied because some reports require two files (multiple filenames)
    return toret.map(row => {
      return {
        ...row,
        filename: reportFileName,
        fileId: reportFileId
      }
    });
  }

  private getLatestProjectId(data: any){
    return data?.items[0]?.id;
  }

  private getReportFileId(files: {id: string, type: number, filename: string}[]): string{
    // El type 1 es FS, el type 6 es tax comp y el type 7 es tax comp previous year    
    // tabNumber === 2 -> FS Checks -> type 1 (solo una file)
    // tabNumber === 3 -> FS vs Comp -> type 1 y type 6
    // tabNumber === 4 -> P/Y vs C/Y Comp -> type 6 y type 7
    // tabNumber === 5 -> Comp checks -> type 6 (solo una file)

    let type: number = 0;

    switch(this.tabNumber){
      case 2:
        type = 1;
        break
      case 3:
        break;
      case 4:
        break;
      case 5:
        type = 6;
        break;
    }
    
    const file = files.find((f: {id: string, type: number, filename: string}) => f.type === type);

    if(file){
      return file.id;
    }
    return "";
  }

  /**
   * This function separates the data in different tables so we have the items that require attention in the upper table
   * as intended in the UI design
   */
  categorizeRows(){

    this.tableData.forEach(data => {
      if(data.flagged){
        this.flaggedData.push(data);
      } else {
        this.passedTestData.push(data);
      }
    });

    if(this.flaggedData.length > 0){
      this.flaggedData.sort((a: any, b: any) => a.order - b.order);
      this.showWarning = true;
    }

    if(this.passedTestData.length > 0){
      this.passedTestData.sort((a: any, b: any) => a.order - b.order);
    }
  }

  /**
   * Sets the necessary configuration for the columns in our table, 
   * TODO: once the proper data is ingested and we can create a model file based on it
   */
  setTableColumnConfiguration(){

  }


  //Buttons functionalities
  closeWarning(){
    this.showWarning = false;
  }
  
  exportTestResults(){
    this.exportingLoader = true;

    switch(this.tabNumber){
      // case 1: is for home, not needed
      case 2: // FS Check
        this.agentFiveService.getFSCheckExported(this.reportId)
        .pipe(take(1))
        .subscribe({
          next: (data: Blob) =>{
            this.exportingLoader = false;
            saveAs(data, "Exported_test_results.pdf");
          },
          error: (error: HttpErrorResponse) => {
            const message : string = error.error?.message || 'An unexpected error occurred';
            this.exportingLoader = false;
            this.toastService.showToast("error", `${message}`);
          }
        });
        break;
      case 3: // FS vs Comp
        break;
      case 4: //  P/Y vs C/Y Comp
        break;
      case 5: // Comp Checks
        break;
    }
  }

  showOnlyFlagged(){
    this.showOnlyFlaggedData = !this.showOnlyFlaggedData;
  }

  backToAgentFiveHome(){
    this.tabNumberEmitter.emit(1);
  }

  scrollUp(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Adds smooth scrolling effect
    });
  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete(); // Signals observables to unsubscribe and cleans up memory
  }
}
