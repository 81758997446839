function C({
  size: o = "16",
  fill: l = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: s = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 12C6.25 11.5858 6.58579 11.25 7 11.25L17 11.25C17.4142 11.25 17.75 11.5858 17.75 12C17.75 12.4142 17.4142 12.75 17 12.75L7 12.75C6.58579 12.75 6.25 12.4142 6.25 12Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M10.25 18C10.25 17.5858 10.5858 17.25 11 17.25L13 17.25C13.4142 17.25 13.75 17.5858 13.75 18C13.75 18.4142 13.4142 18.75 13 18.75L11 18.75C10.5858 18.75 10.25 18.4142 10.25 18Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = i.documentElement;
  if (r) {
    const t = i.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = o;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", l), e.setAttribute("stroke", s), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", l), n.setAttribute("stroke-width", c), n.setAttribute("stroke", s);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { C as IconoirFilterList };