@if(loading === true){
    <div class="progressbar-container">    
        <motif-progress-bar
            [circle]="true"
            [showLabel]="true"
            [customLabel]="true"
        ></motif-progress-bar>
        <p progressbar-label>Loading...</p>
    </div>
}

@if(loading === false && contentAvailable === true){
    <div class="page-header">
        <p class="page-title">Test Summary</p>
        <p class="page-text">Items AI believes require your attention</p>
    </div>

    @if(showWarning === true){
        <app-analysis-warning
            [flaggedItems]="flaggedData"
            [fullData]="tableData"
            (closeEmitter)="closeWarning()"
        ></app-analysis-warning>
    }

    <div class="buttons-container right">
        <button motifButton color="primary" (click)="exportTestResults()" [disabled]="exportingLoader">
            @if(exportingLoader){
                <motif-progress-bar
                    class="button-loader"
                    [circle]="true"
                    [showLabel]="true"
                    [customLabel]="true"
                ></motif-progress-bar>
            }
            @else{
                Export test results
            }
        </button>
        <button motifButton color="primary" (click)="showOnlyFlagged()">{{ showOnlyFlaggedData ? "Show all data" : "Show only flagged" }}</button>
    </div>

    <app-table 
        [data]="flaggedData" 
        [columns]="tableColumns"
    ></app-table>

    @if(!showOnlyFlaggedData){
        <div class="page-header">
            <p class="page-title">All other tests </p>
            <p class="page-text">All other items</p>
        </div>
        
        <app-table 
            [data]="passedTestData" 
            [columns]="tableColumns"
            [bgClass]="{ 'background-color': '#f4fff5' }"
        ></app-table>
    }

    <div class="buttons-container left">
        <button motifButton color="primary-alt" (click)="backToAgentFiveHome()">Agent 5 home</button>
        <button motifButton color="primary-alt" (click)="scrollUp()">Back to top</button>
    </div>
}

@if(loading === false && contentAvailable === false){
    <div class="content-not-available">
        <h3>
            {{ pageMessage }}
        </h3>
    </div>
}