function C({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: o = "",
  stroke: s = "none"
}) {
  const l = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M11.269 5.95636C11.3992 5.67844 11.6849 5.5 11.9997 5.5C12.3145 5.5 12.6002 5.67844 12.7304 5.95636L17.4279 16.3991C17.6113 16.7906 17.4328 17.2521 17.0293 17.43C16.6257 17.6079 16.1498 17.4349 15.9664 17.0434L15.041 15.069H8.95897L8.03362 17.0434C7.85017 17.4349 7.3743 17.6079 6.97073 17.43C6.56715 17.2521 6.3887 16.7906 6.57214 16.3991L11.269 5.95636ZM9.68868 13.512H14.3113L11.9997 8.15987L9.68868 13.512Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 3.5C2.5 2.94771 2.94772 2.5 3.5 2.5H20.5C21.0523 2.5 21.5 2.94772 21.5 3.5V20.5C21.5 21.0523 21.0523 21.5 20.5 21.5H3.5C2.94771 21.5 2.5 21.0523 2.5 20.5V3.5ZM4 20V4H20V20H4Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = l.documentElement;
  if (o) {
    const t = l.createElement("title");
    t.textContent = o, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", s), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", s);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { C as IconoirCustomBackgroundColor };