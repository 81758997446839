function a({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6L12.75 11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12L11.25 6C11.25 5.58579 11.5858 5.25 12 5.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (s) {
    const t = o.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", l), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirClock };