export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: 'e0808b1a-6a81-4339-8841-51f82fabae58',
      authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  apiUrl: 'https://api.cit-assist-dev.ey.net/',
  apiVersion: 'v1/ad/',
};
