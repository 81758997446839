function d({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M20.1299 8.26133C20.5378 8.33306 20.8104 8.72192 20.7387 9.12988L18.7437 20.4761C18.7437 20.4762 18.7437 20.4761 18.7437 20.4761C18.5125 21.791 17.3703 22.75 16.0353 22.75H7.96474C6.62966 22.75 5.48748 21.7911 5.25629 20.4762L3.26133 9.12988C3.1896 8.72192 3.46217 8.33306 3.87012 8.26133C4.27808 8.1896 4.66694 8.46217 4.73867 8.87012L6.73363 20.2164C6.83872 20.8141 7.35791 21.25 7.96474 21.25H16.0353C16.6421 21.25 17.1613 20.8141 17.2663 20.2165L19.2613 8.87012C19.3331 8.46217 19.7219 8.1896 20.1299 8.26133Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M10.625 2.75C9.93464 2.75 9.375 3.30964 9.375 4V5.25H14.625V4C14.625 3.30964 14.0654 2.75 13.375 2.75H10.625ZM7.875 5.25V4C7.875 2.48122 9.10622 1.25 10.625 1.25H13.375C14.8938 1.25 16.125 2.48122 16.125 4V5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H7.875Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (s) {
    const t = o.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", l), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { d as IconoirTrash };