<div class="documents-uploader-container">
    <div class="page-header no-margin-left">
        <p class="page-title">Your documents</p>
        <p class="page-text">Please upload all three required documents to complete all checks. Accepted format is PDF under 10 MB.</p>
    </div>

    <!-- Uploader carousel -->
    <div class="uploaders-container">
        @for(document of documents; track $index){
            <div class="uploader-elements-container">
                <div class="uploader" [ngClass]="{ 'success': document.succeded, 'error': document.hasError }">
                    <motif-icon 
                        size="20" 
                        [iconFunction]="IconoirCloudUpload"
                        class="upload-icon" 
                        [ngClass]="{ 'success': document.succeded, 'error': document.hasError }"
                        (click)="triggerFileUpload($index)"
                    ></motif-icon>
                    
                    <!-- Hidden file input -->
                    <input 
                        type="file" 
                        #fileInput 
                        accept="application/pdf" 
                        (change)="onFileSelected($event, document, $index)" 
                        style="display: none;"
                    />
                    
                    @if(document.file){
                        <div class="doc-overlay">
                            <!-- <p class="file-name" [title]="document.file.name">{{document.file.name}}</p> -->

                            <div>
                                <div motifDropdown>
                                    <button class="dropdown-toggle-button" motifDropdownToggle motifIconButton>
                                        <motif-icon 
                                            class="icono-dropdown" 
                                            size="18" 
                                            [iconFunction]="IconoirMoreHoriz"
                                        ></motif-icon>
                                    </button>
                                    
                                    <div motifDropdownMenu [ngClass]="'motif-theme-light'">
                                        <button motifDropdownItem (click)="viewPdf(document.file)">
                                            <motif-icon size="18" [iconFunction]="IconoirSearch"></motif-icon>
                                            View PDF
                                        </button>
                                        
                                        <button motifDropdownItem (click)="downloadDocument(document.file)">
                                            <motif-icon size="18" [iconFunction]="IconoirDownload"></motif-icon>
                                            Download
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    }

                </div>
                
                <div class="uploader-text-container">
                    <p class="uploader-text-name">{{ document.documentName }}</p>
                    @if(document.file){
                        <p class="uploader-text-file-name" [title]="document.file.name">{{ getFileName(document.file.name) }}</p>
                    }
                    <p class="uploader-text-date">{{ document.uploadDate }}</p>
                </div>
                
                <!-- @if(document.succeded){
                    <div class="uploader-message-container">
                        <motif-icon size="14" class="message-icon success" [iconFunction]="IconoirCheckCircle"></motif-icon>
                        <p class="upload-message success">Successfully loaded document</p>
                    </div>
                } -->
                
                @if(document.hasError){
                    <div class="uploader-message-container">
                        <motif-icon size="14" class="message-icon error" [iconFunction]="IconoirWarningCircle"></motif-icon>
                        <p class="upload-message error">{{document.errorMessage}}</p>
                    </div>
                }
            </div>   
        }
    </div>
    <!-- Uploader carousel -->
</div>