function a({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 16.932C17.25 16.368 17.1077 15.8297 16.8521 15.3564C16.6553 14.9919 16.7912 14.5369 17.1556 14.3401C17.5201 14.1433 17.9751 14.2792 18.1719 14.6436C18.5432 15.3311 18.75 16.1138 18.75 16.932C18.75 19.5929 16.5929 21.75 13.932 21.75H6C5.58579 21.75 5.25 21.4142 5.25 21C5.25 20.5858 5.58579 20.25 6 20.25H13.932C15.7645 20.25 17.25 18.7645 17.25 16.932ZM17.0357 3C17.0357 3.41421 16.6999 3.75 16.2857 3.75H10.068C8.23551 3.75 6.75 5.23551 6.75 7.06797C6.75 8.49613 7.66387 9.76405 9.01874 10.2157L12.2372 11.2885C12.6301 11.4195 12.8425 11.8442 12.7115 12.2372C12.5805 12.6301 12.1558 12.8425 11.7628 12.7115L8.5444 11.6387C6.57701 10.9829 5.25 9.14177 5.25 7.06797C5.25 4.40708 7.40708 2.25 10.068 2.25H16.2857C16.6999 2.25 17.0357 2.58579 17.0357 3Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (s) {
    const t = o.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", l), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirStrikethrough };