function g({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12.4697 5.46967C12.7626 5.17678 13.2374 5.17678 13.5303 5.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L13.5303 18.5303C13.2374 18.8232 12.7626 18.8232 12.4697 18.5303C12.1768 18.2374 12.1768 17.7626 12.4697 17.4697L17.9393 12L12.4697 6.53033C12.1768 6.23744 12.1768 5.76256 12.4697 5.46967Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4.46967 5.46967C4.76256 5.17678 5.23744 5.17678 5.53033 5.46967L11.5303 11.4697C11.8232 11.7626 11.8232 12.2374 11.5303 12.5303L5.53033 18.5303C5.23744 18.8232 4.76256 18.8232 4.46967 18.5303C4.17678 18.2374 4.17678 17.7626 4.46967 17.4697L9.93934 12L4.46967 6.53033C4.17678 6.23744 4.17678 5.76256 4.46967 5.46967Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = o.documentElement;
  if (s) {
    const e = o.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = i;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", l), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { g as IconoirFastArrowRight };