function a({
  size: i = "16",
  fill: l = "currentColor",
  strokeWidth: o = "1",
  title: r = "",
  stroke: s = "none"
}) {
  const C = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M10.3446 7.60313C10.0001 7.89541 9.75 8.34102 9.75 9.00001C9.75 9.41422 9.41421 9.75001 9 9.75001C8.58579 9.75001 8.25 9.41422 8.25 9.00001C8.25 7.90899 8.68736 7.04209 9.37414 6.45937C10.0446 5.89048 10.9119 5.625 11.75 5.625C12.5881 5.625 13.4554 5.89049 14.1259 6.45938C14.8126 7.0421 15.25 7.90899 15.25 9.00001C15.25 9.76589 15.0538 10.3495 14.7334 10.8301C14.4642 11.234 14.1143 11.5462 13.839 11.7919C13.8089 11.8187 13.7798 11.8447 13.7517 11.87C13.4445 12.1464 13.213 12.3743 13.0433 12.6741C12.881 12.9609 12.75 13.3616 12.75 13.9999C12.75 14.4142 12.4142 14.7499 12 14.7499C11.5858 14.7499 11.25 14.4142 11.25 13.9999C11.25 13.1383 11.4315 12.4765 11.7379 11.9352C12.037 11.4069 12.4305 11.041 12.7483 10.755C12.7729 10.7328 12.797 10.7112 12.8205 10.6901C13.1207 10.4204 13.3276 10.2347 13.4853 9.99803C13.6337 9.77553 13.75 9.48414 13.75 9.00001C13.75 8.34103 13.4999 7.89542 13.1554 7.60314C12.7946 7.29702 12.2869 7.125 11.75 7.125C11.2131 7.125 10.7054 7.29702 10.3446 7.60313Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12.5117 17.4414C12.8196 17.7185 12.8446 18.1927 12.5675 18.5006L12.5575 18.5117C12.2804 18.8196 11.8062 18.8446 11.4983 18.5675C11.1904 18.2904 11.1654 17.8162 11.4425 17.5083L11.4525 17.4972C11.7296 17.1893 12.2038 17.1643 12.5117 17.4414Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = C.documentElement;
  if (r) {
    const t = C.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", l), e.setAttribute("stroke", s), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", l), n.setAttribute("stroke-width", o), n.setAttribute("stroke", s);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirHelpCircle };