function u({
  size: C = "16",
  fill: n = "currentColor",
  strokeWidth: o = "1",
  title: r = "",
  stroke: s = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M18 12.5C18.2761 12.5 18.5 12.2761 18.5 12C18.5 11.7239 18.2761 11.5 18 11.5C17.7239 11.5 17.5 11.7239 17.5 12C17.5 12.2761 17.7239 12.5 18 12.5Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M18 12.25C18.1381 12.25 18.25 12.1381 18.25 12C18.25 11.8619 18.1381 11.75 18 11.75C17.8619 11.75 17.75 11.8619 17.75 12C17.75 12.1381 17.8619 12.25 18 12.25ZM16.75 12C16.75 11.3096 17.3096 10.75 18 10.75C18.6904 10.75 19.25 11.3096 19.25 12C19.25 12.6904 18.6904 13.25 18 13.25C17.3096 13.25 16.75 12.6904 16.75 12Z" fill="black" />
<ns0:path d="M12 12.5C12.2761 12.5 12.5 12.2761 12.5 12C12.5 11.7239 12.2761 11.5 12 11.5C11.7239 11.5 11.5 11.7239 11.5 12C11.5 12.2761 11.7239 12.5 12 12.5Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 12.25C12.1381 12.25 12.25 12.1381 12.25 12C12.25 11.8619 12.1381 11.75 12 11.75C11.8619 11.75 11.75 11.8619 11.75 12C11.75 12.1381 11.8619 12.25 12 12.25ZM10.75 12C10.75 11.3096 11.3096 10.75 12 10.75C12.6904 10.75 13.25 11.3096 13.25 12C13.25 12.6904 12.6904 13.25 12 13.25C11.3096 13.25 10.75 12.6904 10.75 12Z" fill="black" />
<ns0:path d="M6 12.5C6.27614 12.5 6.5 12.2761 6.5 12C6.5 11.7239 6.27614 11.5 6 11.5C5.72386 11.5 5.5 11.7239 5.5 12C5.5 12.2761 5.72386 12.5 6 12.5Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M6 12.25C6.13807 12.25 6.25 12.1381 6.25 12C6.25 11.8619 6.13807 11.75 6 11.75C5.86193 11.75 5.75 11.8619 5.75 12C5.75 12.1381 5.86193 12.25 6 12.25ZM4.75 12C4.75 11.3096 5.30964 10.75 6 10.75C6.69036 10.75 7.25 11.3096 7.25 12C7.25 12.6904 6.69036 13.25 6 13.25C5.30964 13.25 4.75 12.6904 4.75 12Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = i.documentElement;
  if (r) {
    const t = i.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = C;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", n), e.setAttribute("stroke", s), e.querySelectorAll("path, line, rect, circle").forEach(l => {
      l.setAttribute("fill", n), l.setAttribute("stroke-width", o), l.setAttribute("stroke", s);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { u as IconoirMoreHoriz };