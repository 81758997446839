function a({
  size: s = "16",
  fill: n = "currentColor",
  strokeWidth: d = "1",
  title: r = "",
  stroke: i = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M7.25001 6.00014C7.25001 5.58593 7.58579 5.25014 8.00001 5.25014L20 5.25014C20.4142 5.25014 20.75 5.58593 20.75 6.00014C20.75 6.41436 20.4142 6.75014 20 6.75014L8.00001 6.75014C7.58579 6.75014 7.25001 6.41435 7.25001 6.00014Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4.51173 5.44156C4.81961 5.71865 4.84457 6.19287 4.56748 6.50075L4.55748 6.51186C4.28038 6.81975 3.80616 6.84471 3.49828 6.56761C3.1904 6.29052 3.16544 5.8163 3.44254 5.50842L3.45254 5.49731C3.72963 5.18942 4.20385 5.16447 4.51173 5.44156Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4.51173 11.4416C4.81961 11.7187 4.84457 12.1929 4.56748 12.5008L4.55748 12.5119C4.28038 12.8197 3.80616 12.8447 3.49828 12.5676C3.1904 12.2905 3.16544 11.8163 3.44254 11.5084L3.45254 11.4973C3.72963 11.1894 4.20385 11.1645 4.51173 11.4416Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4.51173 17.4416C4.81961 17.7187 4.84457 18.1929 4.56748 18.5008L4.55748 18.5119C4.28038 18.8197 3.80616 18.8447 3.49828 18.5676C3.1904 18.2905 3.16544 17.8163 3.44254 17.5084L3.45254 17.4973C3.72963 17.1894 4.20385 17.1645 4.51173 17.4416Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M7.25001 12.0001C7.25001 11.5859 7.58579 11.2501 8.00001 11.2501L20 11.2501C20.4142 11.2501 20.75 11.5859 20.75 12.0001C20.75 12.4144 20.4142 12.7501 20 12.7501L8.00001 12.7501C7.58579 12.7501 7.25001 12.4144 7.25001 12.0001Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M7.25001 18.0001C7.25001 17.5859 7.58579 17.2501 8.00001 17.2501L20 17.2501C20.4142 17.2501 20.75 17.5859 20.75 18.0001C20.75 18.4144 20.4142 18.7501 20 18.7501L8.00001 18.7501C7.58579 18.7501 7.25001 18.4144 7.25001 18.0001Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (r) {
    const t = o.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = s;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", n), e.setAttribute("stroke", i), e.querySelectorAll("path, line, rect, circle").forEach(l => {
      l.setAttribute("fill", n), l.setAttribute("stroke-width", d), l.setAttribute("stroke", i);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirList };