function C({
  size: l = "16",
  fill: s = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M16.7167 2.99999C17.9568 2.99999 19.0435 3.83 19.3698 5.02641L21.2789 12.0264C21.756 13.7758 20.439 15.5 18.6258 15.5H14.2523L15.2168 18.7149C15.4415 19.4641 15.284 20.2759 14.7954 20.8867C13.7588 22.1824 11.7479 22.0472 10.8942 20.6242L8.18375 16.1069C8.00001 15.8006 7.69655 15.5911 7.35344 15.5235C7.32915 15.5187 7.28 15.5113 7.28 15.5113V3.00039C7.28 3.00039 13.571 2.99999 16.7167 2.99999Z" fill="#2E2E38" />
<ns0:path d="M4.26 15.5304H5.8V3.03039H4.26C3.56412 3.03039 3 3.63481 3 4.38039V14.1804C3 14.926 3.56412 15.5304 4.26 15.5304Z" fill="#2E2E38" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (r) {
    const e = i.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", s), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", s), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { C as IconoirCustomThumbsDownSolid };