import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MotifToastModule } from '@ey-xd/ng-motif';

type ToastType = "error" | "warning" | "success" | "info";

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [MotifToastModule, CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent {
  @Input() classTheme: string = '';
  @Input() autoHide: boolean = true;
  @Input() type: ToastType = 'info';
  @Input() actionText: string = '';
  @Input() show: boolean = false;
  @Input() message: string = '';
  @Input() content: string = '';

  @Output() hidden = new EventEmitter<void>();
  @Output() onaction = new EventEmitter<void>();


  action(){
    this.onaction.emit();
  }

  close(){
    this.hidden.emit();
  }
}
