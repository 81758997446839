import {Component, EventEmitter, Output} from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import {
  MotifAvatarModule,
  MotifDropdownModule,
  MotifHeaderModule,
  MotifIconModule, MotifThemeModule,
  MotifVerticalNavigationModule
} from "@ey-xd/ng-motif";
import {NgIf, NgTemplateOutlet} from "@angular/common";
import {
  IconoirCloudUpload,
  IconoirHalfMoon,
  IconoirHelpCircle,
  IconoirHome,
  IconoirLaptopWarning,
  IconoirLogOut,
  IconoirSettings,
  IconoirSunLight,
  IconoirUser,
  IconoirEditPencil,
  IconoirListSelect,
  IconoirCalendar,
  IconoirSearch
} from "@ey-xd/motif-icon";
import {AuthenticationService} from '../../service/auth/authentication.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MotifIconModule,
    MotifHeaderModule,
    MotifDropdownModule,
    MotifAvatarModule,
    MotifVerticalNavigationModule,
    RouterLink,
    NgTemplateOutlet,
    NgIf,
    RouterOutlet,
    MotifThemeModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  constructor(private authenticationService: AuthenticationService) {
  }
  showMenu: boolean = false;
  IconoirSunLight = IconoirSunLight;
  IconoirHalfMoon = IconoirHalfMoon;
  IconoirUser = IconoirUser;
  IconoirLogOut = IconoirLogOut;
  IconoirSettings = IconoirSettings;
  IconoirHelpCircle = IconoirHelpCircle;
  @Output() changeThemeEvent = new EventEmitter();
  @Output() toggleMenuEvent = new EventEmitter();
  theme: string = 'dark';

  toggleMenu(): void {
    this.toggleMenuEvent.emit();
  }

  toggleTheme() {
    this.changeThemeEvent.emit();
  }

  logout() {
    this.authenticationService.logout();
  }
}
