import { Component } from '@angular/core';
import { MotifButtonModule } from "@ey-xd/ng-motif";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Observable, tap } from 'rxjs';
import { AuthenticationService } from '../core/service/auth/authentication.service';
import { Router } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MotifButtonModule,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  loggedIn$: Observable<boolean>;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
    this.loggedIn$ = this.authenticationService.isLoggedIn().pipe(
      tap((loggedIn) => {
        switch (loggedIn) {
          case true:
            this.authenticationService.initializeTokenManagement();
            return this.router.navigate(['home']);
          case false:
            console.log('login', false);
            return false;
          case null:
            console.log('login', null);
            return false;
        }
      }),
    );
  }

  onClickLogin(): void {
    this.authenticationService.login();
  }
}
