import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MotifButtonModule, MotifIconModule, MotifProgressBarModule } from '@ey-xd/ng-motif';
import { CheckCard } from 'app/models/check-card';
import { AgentFiveService } from 'app/core/service/agent-five/agent-five.service';
import { ToastService } from 'app/core/service/toast/toast.service';
import { filter, switchMap, take, tap } from 'rxjs';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-agent-five-home-cards',
  standalone: true,
  imports: [MotifButtonModule, MotifProgressBarModule, MotifIconModule],
  templateUrl: './agent-five-home-cards.component.html',
  styleUrl: './agent-five-home-cards.component.scss'
})
export class AgentFiveHomeCardsComponent {
  @Output() changeToTabEmitter = new EventEmitter<number>();
  @Input() filesToRun: any[] = [];

  ableToRun: boolean = false; // TODO: Improve logic on this so only when there are docs without error and the onchange triggers we can run tests
  private reportStatus: number = 0;

  IconoirWarningCircle = IconoirWarningCircle;
  
  checksArray: CheckCard[] = [
    {
      checkNumber: 1,
      name: "FS Checks",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nulla lorem, tempor nec nibh placerat, vulputate laoreet quam. Integer nisl risus, pretium in ligula sed, bibendum tempor purus. Suspendisse vel ultrices risus. Donec luctus facilisis tristique. Quisque et metus risus. Duis vulputate diam a risus ornare scelerisque.",
      tabNumberOrder: 2,
      disabled: true,
      runLoading: false, // for when the run is loading
      errorMessage: "",
      reportId: undefined,
      reportStatus: 0,
    },
    {
      checkNumber: 2,
      name: "FS vs Comp",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nulla lorem, tempor nec nibh placerat, vulputate laoreet quam. Integer nisl risus, pretium in ligula sed, bibendum tempor purus. Suspendisse vel ultrices risus. Donec luctus facilisis tristique. Quisque et metus risus. Duis vulputate diam a risus ornare scelerisque.",
      tabNumberOrder: 3,
      disabled: true,
      runLoading: false,
      errorMessage: "",
      reportId: undefined,
      reportStatus: 0,
    },
    {
      checkNumber: 3,
      name: "P/Y vs C/Y Comp",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nulla lorem, tempor nec nibh placerat, vulputate laoreet quam. Integer nisl risus, pretium in ligula sed, bibendum tempor purus. Suspendisse vel ultrices risus. Donec luctus facilisis tristique. Quisque et metus risus. Duis vulputate diam a risus ornare scelerisque.",
      tabNumberOrder: 4,
      disabled: true,
      runLoading: false, 
      errorMessage: "",
      // errorMessage: "Test failed: Missing Current Year Tax Computation and Prior Year Tax Computation files",
      reportId: undefined,
      reportStatus: 0,
    },
    {
      checkNumber: 4,
      name: "Comp checks",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nulla lorem, tempor nec nibh placerat, vulputate laoreet quam. Integer nisl risus, pretium in ligula sed, bibendum tempor purus. Suspendisse vel ultrices risus. Donec luctus facilisis tristique. Quisque et metus risus. Duis vulputate diam a risus ornare scelerisque.",
      tabNumberOrder: 5,
      disabled: true,
      runLoading: false,
      errorMessage: "",
      // errorMessage: "Test failed: Missing Current Year Tax Computation file",
      reportId: undefined,
      reportStatus: 0,
    }
  ];

  constructor(
    private agentFiveService: AgentFiveService,
    private toastService: ToastService
  ){}

  ngOnInit(){
    this.checksArray.forEach((check: CheckCard) => {
      const storedCheck = localStorage.getItem(`check-${check.checkNumber}`);
      if (storedCheck) {
        const ch = JSON.parse(storedCheck);
        check.runLoading = ch.runLoading;
      }
      this.isThereALatestRun(check);
    })
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes && changes['filesToRun']){
      this.checksArray.forEach(check => {
        if(check.errorMessage !== ""){
          this.checkErrorMessageOnUpload(check);
        }
      })
    }
  }

  changeToTab(tabNumberOrder: number){
    this.changeToTabEmitter.emit(tabNumberOrder);
  }

  /**
   * Check if there is a "latest" run for the respective check and handle "disable" to go to that latest run
   *  
   */
  private isThereALatestRun(check: CheckCard){
    switch(check.checkNumber){
      case 1:
        // FS Check
        this.agentFiveService.getFSCheckLatestId().pipe(
          take(1),
          tap((data: any) => check.reportId = this.getLatestProjectId(data)),
          tap(() => {
            const storedCheck = localStorage.getItem(`check-${check.checkNumber}`);
            if(storedCheck && check.reportId === undefined){
              check.runLoading = false;
              localStorage.removeItem(`check-${check.checkNumber}`);
              this.toastService.showToast("error", `The process for ${check.name} you were running did not generate a report. Please run the process again or contact support.`, '', '', '', false);
            }
          }),
          filter(() => check.reportId !== undefined),
          switchMap(() => 
            this.agentFiveService.getFSChecksStatus(check.reportId).pipe(
              tap((data: any) => check.reportStatus = this.getLatestProjectStatus(data)),
              tap(() => {
                if(check.reportStatus === 30){ // 30 is success
                  check.disabled = false;
                  check.runLoading = false;
                  const storedCheck = localStorage.getItem(`check-${check.checkNumber}`);
                  if(storedCheck){
                    this.toastService.showToast("success", `${check.name} process is completed. View last run for more details.`);
                    localStorage.removeItem(`check-${check.checkNumber}`);
                  }

                } else if (check.reportStatus === 10 || check.reportStatus === 20){ // 10 & 20 are running
                  setTimeout(() => {
                    this.isThereALatestRun(check)
                  }, 30000);
                } else { // 40 or others are Failures
                  check.disabled = true;
                  check.runLoading = false;
                  const storedCheck = localStorage.getItem(`check-${check.checkNumber}`);
                  if(storedCheck){
                    this.toastService.showToast("error", `${check.name} process failed. Run the process again or contact support.`);
                    localStorage.removeItem(`check-${check.checkNumber}`);
                  }
                }
              })
            )
          )
        ).subscribe()
        break;
      case 2:
        // FS vs Comp
        // // Commented out while the endpoints are not available to avoid errors
        // this.agentFiveService.getFSvsCompLatestId().pipe(
        //   take(1),
        //   tap((data: any) => check.reportId = this.getLatestProjectId(data)),
        //   tap(() => {
        //    const storedCheck = localStorage.getItem(`check-${check.checkNumber}`);
        //    if(storedCheck && check.reportId === undefined){
        //      check.runLoading = false;
        //      localStorage.removeItem(`check-${check.checkNumber}`);
        //      this.toastService.showToast("error", `The process for ${check.name} you were running did not generate a report. Please run the process again or contact support.`, '', '', '', false);
        //    } 
        //   }),
        //   filter(() => check.reportId !== undefined),
        //   switchMap(() => 
        //     this.agentFiveService.getFSvsCompStatus(check.reportId).pipe(
        //       tap((data: any) => check.reportStatus = this.getLatestProjectStatus(data)),
        //       tap(() => {
        //         if(check.reportStatus === 30){ // 30 is success
        //           check.disabled = false;
        //           check.runLoading = false;
        //           const storedCheck = localStorage.getItem(`check-${check.checkNumber}`);
        //           if(storedCheck){
        //             this.toastService.showToast("success", `${check.name} process is completed.View last run for more details.`);
        //             localStorage.removeItem(`check-${check.checkNumber}`);
        //           }                
        //         } else if(check.reportStatus === 10 || check.reportStatus === 20){ // 10 & 20 are running
        //           setTimeout(() => {
        //             this.isThereALatestRun(check)
        //           }, 30000);
        //         } else { // 40 or others are Failures
        //           check.disabled = true;
        //           check.runLoading = false;
        //           const storedCheck = localStorage.getItem(`check-${check.checkNumber}`);
        //           if(storedCheck){
        //             this.toastService.showToast("error", `${check.name} process failed. Run the process again or contact support.`);
        //             localStorage.removeItem(`check-${check.checkNumber}`);
        //           }
        //         }
        //       })
        //     )
        //   )
        // ).subscribe()
        break;
      case 3:
        // P/Y vs C/Y Comp
        break;
      case 4:
        // Comp checks
        break;
    }
  }

  runCheck(checkNumber: number){
    switch(checkNumber){
      case 1:{
        //FS Check
        const check: CheckCard | any = this.checksArray.find(check => check.checkNumber === checkNumber);
        if(check){
          check.runLoading = true;
          check.disabled = true;
        }

        const fileToRun = this.filesToRun.find(document => document.documentNumber === 1)?.file;

        this.agentFiveService.postDocumentToFSChecks(fileToRun).pipe(
          take(1),
          tap(_ =>  {
            // activate the button for the check after posting the document
            if(check){
              const ch = {
                name: check.name,
                checkNumber: check.checkNumber,
                runLoading: true
              }
              localStorage.setItem(`check-${checkNumber}`, JSON.stringify(ch));
              this.isThereALatestRun(check);
            }
          })
        ).subscribe({
          next: (data: any) => {
            check.errorMessage = "";
            this.toastService.showToast('success', `${check.name} processing initialized successfully.`);
            setTimeout(() => {
              this.isThereALatestRun(check)
            }, 30000)
          },
          error: (error: HttpErrorResponse) => {
            if(check.reportId !== undefined){
              check.disabled = false;
            }
            check.runLoading = false;

            if(!fileToRun){
              check.errorMessage = `Test failed: Missing Current Year Financial Statements file.`;
            }
            if(fileToRun){
              const message: string = error.error.message;
              // must be at the end to prevent possible "Already opened Host error" from blocking variable assigning
              this.toastService.showToast('error', `${message}`);
            }
          }
        });
        break;
      }
      case 2:{
        // FS vs Comp
        this.toastService.showToast("warning", "Feature will be available soon");
        // const check: CheckCard | any = this.checksArray.find(check => check.checkNumber === checkNumber);
        // if(check){
        //   check.runLoading = true;
        //   check.disabled = true;
        // }

        // const fileToRun1 = this.filesToRun.find(document => document.documentNumber === 1)?.file;
        // const fileToRun2 = this.filesToRun.find(document => document.documentNumber === 3)?.file;

        // this.agentFiveService.postDocumentsToFSvsComp([fileToRun1, fileToRun2]).pipe(
        //   take(1),
        //   tap(_ =>  {
        //     // activate the button for the check after posting the document
        //     if(check){
        //       const ch = {
        //         name: check.name,
        //         checkNumber: check.checkNumber,
        //         runLoading: true
        //       }
        //       localStorage.setItem(`check-${checkNumber}`, JSON.stringify(ch));
        //       this.isThereALatestRun(check);
        //     }
        //   })
        // ).subscribe({
        //   next: (data: any) => {
        //     check.errorMessage = "";
        //     this.toastService.showToast('success', `${check.name} processing initialized successfully.`);
        //     setTimeout(() => {
        //       this.isThereALatestRun(check)
        //     }, 30000)
        //   },
        //   error: (error: HttpErrorResponse) => {
        //     if(check.reportId !== undefined){
        //       check.disabled = false;
        //     }
        //     check.runLoading = false;

        //     if(!fileToRun1 && !fileToRun2){
        //       check.errorMessage = `Test failed: Missing Current Year Financial Statements and Current Year Tax Computation files.`;
        //     }else if(!fileToRun1 && fileToRun2){
        //       check.errorMessage = `Test failed: Missing Current Year Financial Statements file.`;
        //     }else if(fileToRun1 && !fileToRun2){
        //       check.errorMessage = `Test failed: Missing Current Year Tax Computation file.`;
        //     }

        //     // must be at the end to prevent possible "Already opened Host error" from blocking variable assigning
        //     const message: string = error.error.message
        //     this.toastService.showToast('error', `${message}`);
        //   }
        // });
        break;
      }
      case 3:{
        // P/Y vs C/Y Comp
        this.toastService.showToast("warning", "Feature will be available soon");
        break;
      }
      case 4:{
        // Comp checks
        this.toastService.showToast("warning", "Feature will be available soon");
        break;
      }
    }
  }

  runAllChecks(){
    this.toastService.showToast("warning", "Feature will be available soon");
  }

  private getLatestProjectId(data: any){
    return data?.items[0]?.id;
  }

  private getLatestProjectStatus(data: any){
    return data?.items[0]?.processes[0]?.status;
  }

  private checkErrorMessageOnUpload(check: CheckCard){
    switch(check.checkNumber){
      case 1: {
        const fileToRun = this.filesToRun.find(document => document.documentNumber === 1)?.file;

        if(fileToRun){
          check.errorMessage = "";
        }
        break;
      }
      case 2: {
        const fileToRun1 = this.filesToRun.find(document => document.documentNumber === 1)?.file;
        const fileToRun2 = this.filesToRun.find(document => document.documentNumber === 3)?.file;

        if(!fileToRun1 && fileToRun2){
          check.errorMessage = `Test failed: Missing Current Year Financial Statements file.`;
        } else if(fileToRun1 && !fileToRun2){
          check.errorMessage = `Test failed: Missing Current Year Tax Computation file.`;
        }else if(fileToRun1 && fileToRun2){
          check.errorMessage = "";
        }
        break;
      }
      case 3: {
        const fileToRun1 = this.filesToRun.find(document => document.documentNumber === 3)?.file;
        const fileToRun2 = this.filesToRun.find(document => document.documentNumber === 4)?.file;

        if(!fileToRun1 && fileToRun2){
          check.errorMessage = `Test failed: Missing Current Year Tax Computation file.`;
        } else if(fileToRun1 && !fileToRun2){
          check.errorMessage = `Test failed: Missing Prior Year Tax Computation file.`;
        }else if(fileToRun1 && fileToRun2){
          check.errorMessage = "";
        }
        break;
      }
      case 4: {
        const fileToRun = this.filesToRun.find(document => document.documentNumber === 3)?.file;

        if(fileToRun){
          check.errorMessage = "";
        }
        break;
      }
    }
  }

}
