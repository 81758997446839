<app-agent-breadcrumb></app-agent-breadcrumb>

@if(currentTab.tabNumberOrder === 1){
    <div class="page-header home">
        <p class="page-title">AI Assisted Review Agent</p>
        <p class="page-text">AI will help you perform several financial checks in seconds</p>
    </div>
}

@if(currentTab.tabNumberOrder !== 1){
    <div class="page-header">
        <p class="page-title">AGENT 5</p>
        <p class="page-text">AI will perform checks on the uploaded Financial Statements</p>
    </div>
}

@if(currentTab.tabNumberOrder !== 1){
    <div class="tabs-container">
        @for(tab of tabs; track tab?.tabName){
            <p class="tab" (click)="toggleTab(tab)" [ngClass]="{ 'active': tab.isActive }">
                {{ tab.tabName }}
            </p>
        }
    </div>
}

<div class="tab-content">
    @if(currentTab.tabNumberOrder === 1){
        <app-agent-five-home
            (tabNumberEmitter)="toggleTabOnNumber($event)"
        ></app-agent-five-home>
    }

    @if(currentTab.tabNumberOrder !== 1){
        <app-agent-five-tab
            [tabNumber]="currentTab.tabNumberOrder"
            (tabNumberEmitter)="toggleTabOnNumber($event)"
        ></app-agent-five-tab>        
    }
</div>