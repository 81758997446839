function a({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: C = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M8.85714 7.75001L7.14286 7.75002C4.69635 7.75002 2.75 9.67185 2.75 11.9976C2.75 14.0025 4.19348 15.7051 6.1615 16.139C6.47631 16.2084 6.80467 16.2453 7.14286 16.2453C7.55707 16.2453 7.89286 16.581 7.89286 16.9953C7.89286 17.4095 7.55707 17.7453 7.14286 17.7453C6.69546 17.7453 6.25881 17.6965 5.8385 17.6038C3.22597 17.0278 1.25 14.7496 1.25 11.9976C1.25 8.80321 3.90872 6.25002 7.14286 6.25002L8.85714 6.25001C12.1014 6.25001 14.75 9.09583 14.75 11.9976C14.75 12.4119 14.4142 12.7476 14 12.7476C13.5858 12.7476 13.25 12.4119 13.25 11.9976C13.25 9.91599 11.2647 7.75001 8.85714 7.75001Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M15.1429 16.2452L16.8571 16.2452C19.3036 16.2452 21.25 14.3234 21.25 11.9976C21.25 9.99272 19.8065 8.2902 17.8385 7.85625C17.5237 7.78683 17.1953 7.75 16.8571 7.75C16.4429 7.75 16.1071 7.41421 16.1071 7C16.1071 6.58579 16.4429 6.25 16.8571 6.25C17.3045 6.25 17.7412 6.29875 18.1615 6.39143C20.774 6.96751 22.75 9.2457 22.75 11.9976C22.75 15.1921 20.0913 17.7452 16.8571 17.7452L15.1429 17.7452C11.8986 17.7452 9.25 14.8994 9.25 11.9976C9.25 11.5834 9.58579 11.2476 10 11.2476C10.4142 11.2476 10.75 11.5834 10.75 11.9976C10.75 14.0793 12.7353 16.2452 15.1429 16.2452Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (s) {
    const t = o.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", l), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", C), n.setAttribute("stroke", l);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirLink };