function a({
  size: o = "16",
  fill: l = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: i = "none"
}) {
  const s = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 10C2.25 9.58579 2.58579 9.25 3 9.25H21C21.4142 9.25 21.75 9.58579 21.75 10C21.75 10.4142 21.4142 10.75 21 10.75H3C2.58579 10.75 2.25 10.4142 2.25 10Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 14C2.25 13.5858 2.58579 13.25 3 13.25H21C21.4142 13.25 21.75 13.5858 21.75 14C21.75 14.4142 21.4142 14.75 21 14.75H3C2.58579 14.75 2.25 14.4142 2.25 14Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 18C2.25 17.5858 2.58579 17.25 3 17.25H21C21.4142 17.25 21.75 17.5858 21.75 18C21.75 18.4142 21.4142 18.75 21 18.75H3C2.58579 18.75 2.25 18.4142 2.25 18Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = s.documentElement;
  if (r) {
    const t = s.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = o;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", l), e.setAttribute("stroke", i), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", l), n.setAttribute("stroke-width", c), n.setAttribute("stroke", i);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirAlignJustify };