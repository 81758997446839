function m({
  size: l = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M3.67543 20.7462C3.26328 20.705 2.96257 20.3375 3.00378 19.9253L3.60378 13.9253L4.55354 3.47772C4.61675 2.78236 5.19977 2.24994 5.89799 2.24994H19.693C20.4869 2.24994 21.1094 2.93159 21.0375 3.72217L20.1466 13.5222C20.0834 14.2175 19.5004 14.7499 18.8021 14.7499H5.0288L4.49634 20.0746C4.45512 20.4867 4.08759 20.7874 3.67543 20.7462Z" fill="#2E2E38" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { m as IconoirCustomFlagSolid };