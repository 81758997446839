function a({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M1.32934 8.3349C1.1441 7.96442 1.29427 7.51392 1.66475 7.32867L11.3964 2.46284C11.7765 2.27281 12.2238 2.2728 12.6039 2.46284L22.3356 7.32867C22.7061 7.51392 22.8562 7.96442 22.671 8.3349C22.4857 8.70539 22.0352 8.85556 21.6648 8.67031L12.0002 3.83802L2.33557 8.67031C1.96509 8.85556 1.51459 8.70539 1.32934 8.3349Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4.00016 10.2495C4.41438 10.2495 4.75016 10.5853 4.75016 10.9995V18.9995C4.75016 19.6898 5.30981 20.2495 6.00016 20.2495H18.0002C18.6905 20.2495 19.2502 19.6898 19.2502 18.9995V10.9995C19.2502 10.5853 19.586 10.2495 20.0002 10.2495C20.4144 10.2495 20.7502 10.5853 20.7502 10.9995V18.9995C20.7502 20.5183 19.5189 21.7495 18.0002 21.7495H6.00016C4.48138 21.7495 3.25016 20.5183 3.25016 18.9995V10.9995C3.25016 10.5853 3.58595 10.2495 4.00016 10.2495Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (s) {
    const t = o.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", l), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirHome };