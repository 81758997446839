function a({
  size: s = "16",
  fill: n = "currentColor",
  strokeWidth: o = "1",
  title: i = "",
  stroke: r = "none"
}) {
  const d = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:g clip-path="url(#clip0_1_5798)">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 6.75C9.1005 6.75 6.75 9.1005 6.75 12C6.75 14.8995 9.1005 17.25 12 17.25C14.8995 17.25 17.25 14.8995 17.25 12C17.25 9.1005 14.8995 6.75 12 6.75ZM5.25 12C5.25 8.27208 8.27208 5.25 12 5.25C15.7279 5.25 18.75 8.27208 18.75 12C18.75 15.7279 15.7279 18.75 12 18.75C8.27208 18.75 5.25 15.7279 5.25 12Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 12C21.25 11.5858 21.5858 11.25 22 11.25H23C23.4142 11.25 23.75 11.5858 23.75 12C23.75 12.4142 23.4142 12.75 23 12.75H22C21.5858 12.75 21.25 12.4142 21.25 12Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C11.5858 2.75 11.25 2.41421 11.25 2V1C11.25 0.585786 11.5858 0.25 12 0.25C12.4142 0.25 12.75 0.585786 12.75 1V2C12.75 2.41421 12.4142 2.75 12 2.75Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 23.75C11.5858 23.75 11.25 23.4142 11.25 23V22C11.25 21.5858 11.5858 21.25 12 21.25C12.4142 21.25 12.75 21.5858 12.75 22V23C12.75 23.4142 12.4142 23.75 12 23.75Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L18.4697 19.5303C18.1768 19.2374 18.1768 18.7626 18.4697 18.4697C18.7626 18.1768 19.2374 18.1768 19.5303 18.4697L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M20.5303 3.46967C20.2374 3.17678 19.7626 3.17678 19.4697 3.46967L18.4697 4.46967C18.1768 4.76256 18.1768 5.23744 18.4697 5.53033C18.7626 5.82322 19.2374 5.82322 19.5303 5.53033L20.5303 4.53033C20.8232 4.23744 20.8232 3.76256 20.5303 3.46967Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M3.46967 20.5303C3.76256 20.8232 4.23744 20.8232 4.53033 20.5303L5.53033 19.5303C5.82322 19.2374 5.82322 18.7626 5.53033 18.4697C5.23744 18.1768 4.76256 18.1768 4.46967 18.4697L3.46967 19.4697C3.17678 19.7626 3.17678 20.2374 3.46967 20.5303Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L5.53033 4.46967C5.82322 4.76256 5.82322 5.23744 5.53033 5.53033C5.23744 5.82322 4.76256 5.82322 4.46967 5.53033L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 12C0.25 11.5858 0.585786 11.25 1 11.25H2C2.41421 11.25 2.75 11.5858 2.75 12C2.75 12.4142 2.41421 12.75 2 12.75H1C0.585786 12.75 0.25 12.4142 0.25 12Z" fill="black" />
</ns0:g>
<ns0:defs>
<ns0:clipPath id="clip0_1_5798">
<ns0:rect width="24" height="24" fill="white" />
</ns0:clipPath>
</ns0:defs>
</ns0:svg>`, "image/svg+xml"),
    e = d.documentElement;
  if (i) {
    const l = d.createElement("title");
    l.textContent = i, e.prepend(l);
  }
  if (e instanceof SVGElement) {
    const l = s;
    return e.setAttribute("width", l), e.setAttribute("height", l), e.setAttribute("fill", n), e.setAttribute("stroke", r), e.querySelectorAll("path, line, rect, circle").forEach(t => {
      t.setAttribute("fill", n), t.setAttribute("stroke-width", o), t.setAttribute("stroke", r);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirSunLight };