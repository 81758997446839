function u({
  size: l = "16",
  fill: s = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M6.5 5C6.5 4.58579 6.16421 4.25 5.75 4.25C5.33579 4.25 5 4.58579 5 5V19C5 19.4142 5.33579 19.75 5.75 19.75C6.16421 19.75 6.5 19.4142 6.5 19V12.75H13V19C13 19.4142 13.3358 19.75 13.75 19.75C14.1642 19.75 14.5 19.4142 14.5 19V5C14.5 4.58579 14.1642 4.25 13.75 4.25C13.3358 4.25 13 4.58579 13 5V11.25H6.5V5Z" fill="black" />
<ns0:path d="M19 19C19 19.4142 18.6642 19.75 18.25 19.75C17.8358 19.75 17.5 19.4142 17.5 19V12C17.5 11.5858 17.8358 11.25 18.25 11.25C18.6642 11.25 19 11.5858 19 12V19Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (r) {
    const e = i.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", s), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", s), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { u as IconoirCustomHeading1 };