function h({
  size: l = "16",
  fill: r = "currentColor",
  strokeWidth: C = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M5.17139 5.42163C5.92188 4.67139 6.93896 4.25 8 4.25H15.25C15.4648 4.25 15.6587 4.34033 15.7954 4.48511C15.8418 4.53406 15.8813 4.58911 15.9131 4.64905C15.9688 4.75378 16 4.87317 16 5C16 5.41418 15.6641 5.75 15.25 5.75H13.5V19C13.5 19.1578 13.4512 19.3043 13.3677 19.4252C13.2324 19.6215 13.0063 19.75 12.75 19.75C12.3359 19.75 12 19.4142 12 19V5.75H9.5V19C9.5 19.4142 9.16406 19.75 8.75 19.75C8.62598 19.75 8.50928 19.72 8.40674 19.6669C8.33643 19.6305 8.27246 19.5833 8.21729 19.5275C8.08301 19.392 8 19.2057 8 19V12.25C6.93896 12.25 5.92188 11.8286 5.17139 11.0784C4.42139 10.3282 4 9.31091 4 8.25C4 7.18909 4.42139 6.17175 5.17139 5.42163Z" fill="black" />
<ns0:path d="M19.7803 9.46973C20.0732 9.76257 20.0732 10.2374 19.7803 10.5303L18.3105 12L19.7803 13.4697C20.0732 13.7626 20.0732 14.2374 19.7803 14.5303C19.4873 14.8232 19.0127 14.8232 18.7197 14.5303L16.7197 12.5303C16.6357 12.4464 16.5757 12.3475 16.54 12.2423C16.4507 11.9802 16.5107 11.6787 16.7197 11.4697L18.7197 9.46973C19.0127 9.17676 19.4873 9.17676 19.7803 9.46973Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", C), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { h as IconoirCustomParagraphRightToLeft };