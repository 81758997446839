@if(type === 'text'){
    <ng-container>
        {{ row[field] }}
    </ng-container>
}

@if(type === 'text-quoting-document'){
    <ng-container>
        {{ row[field] }}
        <br/><br/>
        <div class="ref-pages-text">The result is based on 
            @for (page of reportPages; track $index) {
                <p 
                    [ngClass]="{'last-page': isLastPage($index)}"
                    (click)="isLastPage($index) ? false : goToPage(page, row['fileId'])"
                >
                    {{ page }}
                </p>
                @if(!isLastPage($index) && $index !== reportPages.length - 2){
                  <p class="last-page">,</p>
                }
            }
        </div>
    </ng-container>
}

@if(type === 'link'){
    <ng-container>
        <a [href]="row[field]" target="_blank" rel="noopener noreferrer">
            {{ row[field] || 'Link' }}
        </a>
    </ng-container>
}

@if(type === 'flagged-number') {
    <ng-container>
  
      @if(row['flagged']) {
        <div class="flag-number-container">
          <motif-icon 
            [iconFunction]="IconoirWhiteFlag" 
            class="flag-icon"
            size="18"
          ></motif-icon>
          <p class="flag-number">{{ row[field] }}</p>
        </div>
    } 
    
    @if(!row['flagged']){
        <p class="flag-number">{{ row[field] }}</p>
    }

    </ng-container>
  }

  