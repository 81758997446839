function m({
  size: l = "16",
  fill: n = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString('<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><ns0:path d="M3.27 5L2 6.27l6.97 6.97L6.5 19h3l1.57-3.66L16.73 21 18 19.73 3.55 5.27 3.27 5zM6 5v.18L8.82 8h2.4l-.72 1.68 2.1 2.1L14.21 8H20V5H6z" /></ns0:svg>', "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", n), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(r => {
      r.setAttribute("fill", n), r.setAttribute("stroke-width", c), r.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { m as MotifEditorIcFormatClear24px };