<table [ngStyle]="bgClass">
    <thead>
      <tr>
        <th *ngFor="let column of columns">
          {{ column.header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data">
        <td *ngFor="let column of columns" [ngClass]="column.class">
            <app-column 
                [row]="row" 
                [field]="column.field" 
                [type]="column.type"
            ></app-column>
        </td>
      </tr>
    </tbody>
</table>
  