import { Component, EventEmitter, Output } from '@angular/core';
import { DocumentsUploaderComponent } from '../documents-uploader/documents-uploader.component';
import { AgentFiveHomeCardsComponent } from '../agent-five-home-cards/agent-five-home-cards.component';

@Component({
  selector: 'app-agent-five-home',
  standalone: true,
  imports: [DocumentsUploaderComponent, AgentFiveHomeCardsComponent],
  templateUrl: './agent-five-home.component.html',
  styleUrl: './agent-five-home.component.scss'
})
export class AgentFiveHomeComponent {
  @Output() tabNumberEmitter = new EventEmitter<number>();
  filesToRun: any[] = [];

  toggleToTabNumber($event: number){
    this.tabNumberEmitter.emit($event)
  }
  
  getUploadedFiles($event: any[]){
    this.filesToRun = [...$event];
  }
}
