function g({
  size: l = "16",
  fill: s = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M15.5 4H17.4C18.1456 4 18.75 4.60442 18.75 5.35V7.03281C18.75 7.7784 18.1456 8.38281 17.4 8.38281L16.25 8.38281V9.63281L18 9.63281C18.4142 9.63281 18.75 9.9686 18.75 10.3828C18.75 10.797 18.4142 11.1328 18 11.1328H16.1C15.3544 11.1328 14.75 10.5284 14.75 9.78281V8.23281C14.75 7.48723 15.3544 6.88281 16.1 6.88281L17.25 6.88281V5.5L15.5 5.5C15.0858 5.5 14.75 5.16421 14.75 4.75C14.75 4.33579 15.0858 4 15.5 4Z" fill="black" />
<ns0:path d="M5.55004 7.34926C5.88142 7.10073 6.35152 7.16789 6.60004 7.49926L9.00004 10.6993L11.4 7.49926C11.6486 7.16789 12.1187 7.10073 12.45 7.34926C12.7814 7.59779 12.8486 8.06789 12.6 8.39926L9.93754 11.9493L12.6 15.4993C12.8486 15.8306 12.7814 16.3007 12.45 16.5493C12.1187 16.7978 11.6486 16.7306 11.4 16.3993L9.00004 13.1993L6.60004 16.3993C6.35152 16.7306 5.88142 16.7978 5.55004 16.5493C5.21867 16.3007 5.15152 15.8306 5.40004 15.4993L8.06254 11.9493L5.40004 8.39926C5.15152 8.06789 5.21867 7.59779 5.55004 7.34926Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (r) {
    const e = i.createElement("title");
    e.textContent = r, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", s), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", s), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { g as IconoirCustomSubscript };