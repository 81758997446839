<div class="sign-in" id="signInContainer" *ngIf="(loggedIn$ | async) === false">
  <img class="ey-login-icon" alt="login-background" src="assets/img/login-background.svg">  
  <div class="rectangle-parent">
    <div class="group-child">
      <img class="vector-icon" alt="login-logo" src="assets/img/ey_Logo.png">
    </div>
    <div class="button-wrapper">
      <button motif-button class="button2" (click)="onClickLogin()">
          <div class="text">I am an EY employee</div>
      </button>
    </div>
    <div class="label">
      <div class="text">Please sign in with SSO below</div>
      <div class="required">(required)</div>
    </div>
    <div class="text1">Welcome to CIT Agent</div>
  </div>
</div>
