function g({
  size: l = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M8.66919 2.36249C8.88986 2.49925 9.02411 2.74039 9.02411 3C9.02411 7.13921 10.0503 10.0955 11.9774 12.0226C13.9045 13.9497 16.8608 14.9759 21 14.9759C21.2596 14.9759 21.5008 15.1101 21.6375 15.3308C21.7743 15.5515 21.7872 15.8272 21.6716 16.0597C19.9964 19.4307 16.5164 21.75 12.4934 21.75C6.83613 21.75 2.25 17.1639 2.25 11.5066C2.25 7.48357 4.5693 4.00363 7.94034 2.32837C8.17282 2.21283 8.44852 2.22574 8.66919 2.36249ZM7.55715 4.28875C5.25722 5.86473 3.75 8.51018 3.75 11.5066C3.75 16.3354 7.66456 20.25 12.4934 20.25C15.4898 20.25 18.1353 18.7428 19.7112 16.4429C15.9583 16.2455 12.9936 15.1601 10.9168 13.0833C8.83989 11.0064 7.75451 8.0417 7.55715 4.28875Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = l;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { g as IconoirHalfMoon };