import { Component } from '@angular/core';

@Component({
  selector: 'app-tb-agent',
  standalone: true,
  imports: [],
  templateUrl: './tb-agent.component.html',
  styleUrl: './tb-agent.component.scss'
})
export class TbAgentComponent {

}
