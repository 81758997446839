function h({
  size: C = "16",
  fill: r = "currentColor",
  strokeWidth: l = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const i = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path d="M16.7501 19.75C16.336 19.75 16.0001 19.4142 16.0001 19V5.75H13.5001V19C13.5001 19.1827 13.4346 19.3502 13.3262 19.4803C13.1885 19.6451 12.9815 19.75 12.7501 19.75C12.336 19.75 12.0001 19.4142 12.0001 19V12.25C10.939 12.25 9.92193 11.8286 9.17144 11.0784C8.42144 10.3282 8.00005 9.31091 8.00005 8.25C8.00005 7.18909 8.42144 6.17175 9.17144 5.42163C9.92193 4.67139 10.939 4.25 12.0001 4.25H19.2501C19.6641 4.25 20.0001 4.58582 20.0001 5C20.0001 5.20056 19.9214 5.38269 19.793 5.51721C19.6563 5.66064 19.4634 5.75 19.2501 5.75H17.5001V19C17.5001 19.1692 17.4439 19.3253 17.3492 19.4508C17.2466 19.5874 17.0982 19.6876 16.9268 19.7291C16.8702 19.7428 16.8111 19.75 16.7501 19.75Z" fill="black" />
<ns0:path d="M4.00152 10.0472C4.01275 10.2231 4.0855 10.3959 4.21978 10.5303L5.68951 12L4.21978 13.4697C3.92681 13.7626 3.92681 14.2374 4.21978 14.5303C4.26568 14.576 4.31548 14.6146 4.3687 14.6461C4.43267 14.6837 4.50054 14.7112 4.57085 14.7284C4.81695 14.7888 5.08794 14.7228 5.28033 14.5303L7.28033 12.5303C7.41851 12.392 7.49175 12.213 7.49956 12.0317C7.50786 11.8293 7.43511 11.6243 7.28033 11.4697L5.28033 9.46973C4.98736 9.17676 4.51275 9.17676 4.21978 9.46973C4.06109 9.62817 3.98833 9.83997 4.00152 10.0472Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    t = i.documentElement;
  if (s) {
    const e = i.createElement("title");
    e.textContent = s, t.prepend(e);
  }
  if (t instanceof SVGElement) {
    const e = C;
    return t.setAttribute("width", e), t.setAttribute("height", e), t.setAttribute("fill", r), t.setAttribute("stroke", o), t.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", l), n.setAttribute("stroke", o);
    }), t;
  }
  throw new Error("Failed to create SVG element.");
}
export { h as IconoirCustomParagraphLeftToRight };