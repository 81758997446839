import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FailedComponent } from './failed/failed.component';
import { LoginComponent } from './login/login.component';
import { MsalGuard } from '@azure/msal-angular';
import { ProfileComponent } from './profile/profile.component';
import { TbAgentComponent } from './core/tb-agent/tb-agent.component';
import { QuestionsAgentComponent } from './core/questions-agent/questions-agent.component';
import { AgentFiveComponent } from './core/agent-five/agent-five.component';

export const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: 'tb-agent',
    component: TbAgentComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'questions-agent',
    component: QuestionsAgentComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'agent-five',
    component: AgentFiveComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
  }
];
