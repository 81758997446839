<div>
  <header motif-header>
    <div position="left" class="header-left">
      <button
        motif-header-menu
        (click)="toggleMenu()"
        [isOpen]="false"
        aria-label="Toggle menu"
      ></button>
      <img
        motif-header-logo
        alt="Product name"
        src="assets/img/EY_Logo_Beam_RGB_White_Yellow.png"
      />
      <span motif-header-title>CIT Agent</span>
    </div>

    <motif-icon strokeWidth="2" size="24"></motif-icon>
    <button motif-header-avatar position="right" aria-label="Avatar">
      <!-- <motif-avatar
        src="./ashleigh-franklin.png"
        className="undefined"
        size="medium"
      ></motif-avatar> -->
    </button>
  </header>
</div>