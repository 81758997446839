import { Injectable, ComponentRef, Injector } from '@angular/core';
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ToastComponent } from 'app/core/components/toast/toast.component'; 

type ToastType = "error" | "warning" | "success" | "info";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private overlayRef: OverlayRef | null = null;

  constructor(private overlay: Overlay, private injector: Injector) {}

  // Function to show the toast
  showToast(type: ToastType, message: string, classTheme = 'motif-theme-dark', content: string = '', actionText: string = '', autoHide: boolean = true, duration: number = 3000): Promise<boolean> {
    return new Promise((resolve) => {
      // if there is a previous toast, get rid of it to 
      // show a new one and avoid errors
      if(this.overlayRef){
        this.overlayRef.detach();
      }

      if (!this.overlayRef) {
        this.overlayRef = this.overlay.create({
          hasBackdrop: false,
          positionStrategy: this.getToastPosition(),
          panelClass: 'toast-panel'
        });
      }

      // Create a portal for the ToastComponent
      const toastPortal = new ComponentPortal(ToastComponent, null, this.injector);
      const toastRef: ComponentRef<ToastComponent> = this.overlayRef.attach(toastPortal);

      // Set the input properties for the ToastComponent
      toastRef.instance.classTheme = classTheme;
      toastRef.instance.autoHide = autoHide;
      toastRef.instance.type = type;
      toastRef.instance.message = message;
      toastRef.instance.content = content;
      toastRef.instance.actionText = actionText;
      toastRef.instance.show = true;

      // Automatically hide the toast after the specified duration
      if(autoHide){
        setTimeout(() => {
          toastRef.instance.show = false;  // Hide the toast
          this.overlayRef?.detach();  // Remove the toast from the overlay
        }, duration);
      } else {
        toastRef.instance.hidden.subscribe(() => {
          toastRef.instance.show = false;  // Hide the toast
          this.overlayRef?.detach();  // Remove the toast from the overlay
          resolve(false);
        });
        
        toastRef.instance.onaction.subscribe(() => {
          toastRef.instance.show = false;  // Hide the toast
          this.overlayRef?.detach();  // Remove the toast from the overlay
          resolve(true);
        });
      }
    });
  }

  // Function to define the position of the toast
  private getToastPosition(): PositionStrategy {
    return this.overlay.position().global().centerHorizontally().top('10px');
  }
}
