function a({
  size: o = "16",
  fill: n = "currentColor",
  strokeWidth: C = "1",
  title: d = "",
  stroke: i = "none"
}) {
  const r = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.25C6.41421 4.25 6.75 4.58579 6.75 5V7C6.75 7.41421 6.41421 7.75 6 7.75C5.58579 7.75 5.25 7.41421 5.25 7V5C5.25 4.58579 5.58579 4.25 6 4.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V11C10.75 11.4142 10.4142 11.75 10 11.75C9.58579 11.75 9.25 11.4142 9.25 11V5C9.25 4.58579 9.58579 4.25 10 4.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M18 4.25C18.4142 4.25 18.75 4.58579 18.75 5V6C18.75 6.41421 18.4142 6.75 18 6.75C17.5858 6.75 17.25 6.41421 17.25 6V5C17.25 4.58579 17.5858 4.25 18 4.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M6 9.25C6.41421 9.25 6.75 9.58579 6.75 10V16C6.75 16.4142 6.41421 16.75 6 16.75C5.58579 16.75 5.25 16.4142 5.25 16V10C5.25 9.58579 5.58579 9.25 6 9.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M6 17.75C6.41421 17.75 6.75 18.0858 6.75 18.5V19C6.75 19.4142 6.41421 19.75 6 19.75C5.58579 19.75 5.25 19.4142 5.25 19V18.5C5.25 18.0858 5.58579 17.75 6 17.75Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M10 17.75C10.4142 17.75 10.75 18.0858 10.75 18.5V19C10.75 19.4142 10.4142 19.75 10 19.75C9.58579 19.75 9.25 19.4142 9.25 19V18.5C9.25 18.0858 9.58579 17.75 10 17.75Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M10 13.25C10.4142 13.25 10.75 13.5858 10.75 14V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V14C9.25 13.5858 9.58579 13.25 10 13.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M14 12.25C14.4142 12.25 14.75 12.5858 14.75 13V15C14.75 15.4142 14.4142 15.75 14 15.75C13.5858 15.75 13.25 15.4142 13.25 15V13C13.25 12.5858 13.5858 12.25 14 12.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M14 4.25C14.4142 4.25 14.75 4.58579 14.75 5V10C14.75 10.4142 14.4142 10.75 14 10.75C13.5858 10.75 13.25 10.4142 13.25 10V5C13.25 4.58579 13.5858 4.25 14 4.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M18 8.25C18.4142 8.25 18.75 8.58579 18.75 9V13C18.75 13.4142 18.4142 13.75 18 13.75C17.5858 13.75 17.25 13.4142 17.25 13V9C17.25 8.58579 17.5858 8.25 18 8.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M14.4697 18.4697C14.7626 18.1768 15.2374 18.1768 15.5303 18.4697L17 19.9393L20.4697 16.4697C20.7626 16.1768 21.2374 16.1768 21.5303 16.4697C21.8232 16.7626 21.8232 17.2374 21.5303 17.5303L17.5303 21.5303C17.2374 21.8232 16.7626 21.8232 16.4697 21.5303L14.4697 19.5303C14.1768 19.2374 14.1768 18.7626 14.4697 18.4697Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = r.documentElement;
  if (d) {
    const l = r.createElement("title");
    l.textContent = d, e.prepend(l);
  }
  if (e instanceof SVGElement) {
    const l = o;
    return e.setAttribute("width", l), e.setAttribute("height", l), e.setAttribute("fill", n), e.setAttribute("stroke", i), e.querySelectorAll("path, line, rect, circle").forEach(t => {
      t.setAttribute("fill", n), t.setAttribute("stroke-width", C), t.setAttribute("stroke", i);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirShoppingCodeCheck };