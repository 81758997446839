function g({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: l = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 2.75V21.25H19.25V5.81066L16.1893 2.75H4.75ZM3.25 2.6C3.25 1.85441 3.85442 1.25 4.6 1.25H16.2515C16.6095 1.25 16.9529 1.39223 17.2061 1.64541L20.3546 4.79393C20.6078 5.04711 20.75 5.39048 20.75 5.74853V21.4C20.75 22.1456 20.1456 22.75 19.4 22.75H4.6C3.85441 22.75 3.25 22.1456 3.25 21.4V2.6Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M16 1.25C16.4142 1.25 16.75 1.58579 16.75 2V5.25H20C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75H16.6C15.8544 6.75 15.25 6.14558 15.25 5.4V2C15.25 1.58579 15.5858 1.25 16 1.25Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (s) {
    const t = o.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", l), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", l);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { g as IconoirEmptyPage };