import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterLink, RouterOutlet} from '@angular/router';
import {
  MOTIF_DATE_TIME_FORMATS,
  MotifAvatarModule,
  MotifDropdownModule,
  MotifHeaderModule,
  MotifIconModule,
  MotifThemeModule,
  MotifVerticalNavigationModule
} from "@ey-xd/ng-motif";
import {CommonModule} from "@angular/common";
import {
  IconoirHalfMoon,
  IconoirHelpCircle,
  IconoirHome,
  IconoirLogOut,
  IconoirSettings,
  IconoirSunLight,
  IconoirUser,
  IconoirCalendar, IconoirUpload,
  IconoirTable, IconoirShoppingCodeCheck, IconoirUserBadgeCheck, IconoirChatBubbleQuestion

} from "@ey-xd/motif-icon";
import {HeaderComponent} from './core/components/header/header.component';
import {CoreModule} from './core/core.module';
import {Subject, Subscription, takeUntil} from 'rxjs';
import {AuthenticationService} from './core/service/auth/authentication.service';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {AuthenticationResult, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {filter} from 'rxjs/operators';
import { FooterComponent } from './core/components/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLink, MotifHeaderModule, MotifVerticalNavigationModule,
    MotifThemeModule, MotifAvatarModule,
    MotifIconModule, CommonModule, MotifDropdownModule, HeaderComponent, CoreModule, FooterComponent],
  providers: [
    {provide: MOTIF_DATE_TIME_FORMATS, useValue: {...MOTIF_DATE_TIME_FORMATS,
        datePickerInput: { year: 'numeric'}}},
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy{
  showMenu: boolean = false;
  IconoirSunLight = IconoirSunLight;
  IconoirHalfMoon = IconoirHalfMoon;
  IconoirUser = IconoirUser;
  IconoirLogOut = IconoirLogOut;
  IconoirSettings = IconoirSettings;
  IconoirHelpCircle = IconoirHelpCircle;
  IconoirTable = IconoirTable;
  IconoirShoppingCodeCheck = IconoirShoppingCodeCheck;
  IconoirUserBadgeCheck = IconoirUserBadgeCheck;
  IconoirChatBubbleQuestion = IconoirChatBubbleQuestion;
  items = [
    {
      label: 'Home',
      icon: IconoirHome,
      exact: true,
      routerLink: ['']
    },
    {
      label: 'TB Extraction Agent',
      icon: IconoirTable,
      routerLink: ['/tb-agent'],
      subMenu: []
    },
    {
      label: 'Question Generation Agent',
      icon: IconoirChatBubbleQuestion,
      routerLink: ['/questions-agent'],
      subMenu: []
    },
    {
      label: 'AI Review Agent',
      icon: IconoirUserBadgeCheck,
      routerLink: ['/agent-five'],
      subMenu: []
    }
  ];
  theme: string = 'dark';
//-----------------------------------
  isIframe = false;
  loginDisplay = false;
  private loggedInSubscription?: Subscription | null;
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              public router: Router,
              protected authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.inProgress$.subscribe((status: InteractionStatus) => {
      // console.log('MSAL Event: InProgress', status);
      if (status === InteractionStatus.None) {
        this.authenticationService.checkAccount();
      }
    });

    this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED)).subscribe((result: EventMessage) => {
      // console.log('Filtered MSAL Event:' + result.eventType, result);
      if (this.authService.instance.getAllAccounts().length === 0) {
        console.log('MSAL Event: No accounts detected, redirecting to root');
        window.location.pathname = '/';
      }
    });
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
      .subscribe((result: EventMessage) => {
        if (result.payload && 'account' in result.payload) {
          const payload = result.payload as AuthenticationResult;
          this.authenticationService.checkAccount(payload.account);
        }
        else {
          console.error('No account in result payload');
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe((result: EventMessage) => {
        // console.log('Filtered MSAL Event:' + result.eventType, result);
        if (this.authService.instance.getActiveAccount() == null) {
          window.location.pathname = '/login';
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
    if (this.authenticationService.loggedIn) {
    } else {
      this.loggedInSubscription = this.authenticationService.isLoggedIn().subscribe((x) => {
        if (x) {
          this.loggedInSubscription?.unsubscribe();
          this.loggedInSubscription = null;
        } else if (!x) {
          this.router.navigate(['login']);
        }
      });
    }
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  toggleTheme(): void {
    this.theme = this.theme === 'dark' ? 'light' : 'dark';
  }
}
