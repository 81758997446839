function a({
  size: i = "16",
  fill: l = "currentColor",
  strokeWidth: c = "1",
  title: r = "",
  stroke: s = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16.5C12.75 16.9142 12.4142 17.25 12 17.25C11.5858 17.25 11.25 16.9142 11.25 16.5V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12.5117 6.94142C12.8196 7.21851 12.8446 7.69273 12.5675 8.00061L12.5575 8.01172C12.2804 8.31961 11.8062 8.34456 11.4983 8.06747C11.1904 7.79038 11.1654 7.31616 11.4425 7.00828L11.4525 6.99716C11.7296 6.68928 12.2038 6.66432 12.5117 6.94142Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (r) {
    const t = o.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", l), e.setAttribute("stroke", s), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", l), n.setAttribute("stroke-width", c), n.setAttribute("stroke", s);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirInfoCircle };