import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../core/service/auth/authentication.service';
import { CoreModule } from '../core/core.module';
import { MotifCardModule } from '@ey-xd/ng-motif';
import { MotifIconModule } from  '@ey-xd/ng-motif';
import { MotifButtonModule } from  '@ey-xd/ng-motif';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: true,
  imports: [CommonModule, CoreModule,MotifCardModule, MotifIconModule, MotifButtonModule],
})
export class HomeComponent implements OnInit {

  cardValues = {
    image: './card-image-vr-min.jpg',
    altText: 'Woman and man impressed with vr headset',
    header: {
      title: 'Design',
      subtitle: 'UX review presentations'
    },
    body: 'How do you create compelling presentations that wow your colleagues and impress your managers?'
  };

  constructor(
    protected authenticationService: AuthenticationService,
    protected router: Router,
  ) {}

  ngOnInit(): void {
  }

  triggerAction() {
    alert('Button clicked');
  }

  goToAgentFive(){
    this.router.navigate(['/agent-five']);
  }

}

