import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/core/service/auth/authentication.service';
import { environment } from 'environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
    private url: string = `${environment.apiUrl}`;

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ){}

    public get(
        slug: string,
        paramsServer: any,
        message: string,
        requestParams = {},
    ): Observable<any> {

        let finalUrl = this.url + slug;

        if (paramsServer !== '') {
            finalUrl = finalUrl + this.getQueryString(paramsServer);
        }
    
        return this.http.get(finalUrl, {headers: this.getHeaders(), ...requestParams})
            .pipe(
                map(res => {
                    if (message) {
                        console.log('get: ', message);
                    }
                    return res;
                }),
                catchError(error => {
                    return throwError(() => new HttpErrorResponse(error));
                }
            ),
        );
    }

    public post(
        slug: string,
        data: any,
        message: string,
        requestParams = {},
    ): Observable<any> {

        const finalUrl = this.url + slug + '/';

        return this.http.post(finalUrl, data, {headers: this.getHeaders(), ...requestParams})
            .pipe(
                map(res => {
                    const obj = res;

                    if (message) {
                        console.log('post: ', message);
                    }
                    return obj;
                }),
                catchError(error => {
                    return throwError(() => new HttpErrorResponse(error));
                }
            )
        );
    
    }

    public put(
        slug: string,
        id: string,
        data: any,
        message: string,
        requestParams = {},
    ): Observable<any> {

        const finalUrl = this.url + slug + id + '/';

        return this.http.patch(finalUrl, data, {headers: this.getHeaders(), ...requestParams})
            .pipe(
                map(res => {
                    const obj = res;
                    console.log('put: ', message);
                    return obj;
                }),
                catchError(error => {
                    return throwError(() => new HttpErrorResponse(error));
                }
            ),
        );
    }

    public patch(
        slug: string,
        id: string,
        data: any,
        message: string,
        requestParams = {}
    ): Observable<any> {

        const finalUrl = this.url + slug + id + '/';

        return this.http.patch(finalUrl, data, {headers: this.getHeaders(), ...requestParams})
            .pipe(
                map(res => {
                    const obj = res;
                    console.log('patch: ', message)
                    return obj;
                }),
                catchError(error => {
                    return throwError(() => new HttpErrorResponse(error));
                }
            ),
        );
    }

    public delete(
        slug: string,
        id: string,
        message: string,
        requestParams = {}
    ): Observable<any> {
        
        const finalUrl = this.url + slug + id + '/';

        return this.http.delete(finalUrl, {headers: this.getHeaders(), ...requestParams})
            .pipe(
                map(res => {
                    const obj = res;
                    if (message) {
                        console.log('delete: ', message);
                    }
                    return obj;
                }),
                catchError(error => {
                    return throwError(() => new HttpErrorResponse(error));
                }
            )
        );
    }

    /*
     * Private string manipulator functions to process query string and error messages
    */

    private getQueryString(params: any) {
        if (typeof params === 'undefined' || typeof params !== 'object') {
          params = {};
          return params;
        }
    
        let query = '?';
        let index = 0;
    
        for (const i in params) {
          index++;
          const param = i;
          const value = params[i];
          query += index === 1 ? param + '=' + value : '&' + param + '=' + value;
        }
        return query;
    }

    /**
     * Function to parse correctly the necessary headers
     * @return HttpHeaders
     */
    private getHeaders(){
        const token = this.authenticationService.getToken();

        if (!token) {
            throw new Error('No valid token available.');
        }

        const headers = {
            'Authorization' : `Bearer ${token}`,
        }

        return new HttpHeaders(headers);
    }

}
