import { Component, ElementRef, QueryList, ViewChildren, Output, EventEmitter } from '@angular/core';
import { MotifDropdownModule, MotifIconModule } from '@ey-xd/ng-motif';
import { IconoirCloudUpload, IconoirCheckCircle, IconoirDownload, IconoirSearch, IconoirWarningCircle, IconoirMoreHoriz } from '@ey-xd/motif-icon';
import { CommonModule } from '@angular/common';
import { ToastService } from 'app/core/service/toast/toast.service';

@Component({
  selector: 'app-documents-uploader',
  standalone: true,
  imports: [MotifIconModule, CommonModule, MotifDropdownModule],
  templateUrl: './documents-uploader.component.html',
  styleUrl: './documents-uploader.component.scss'
})
export class DocumentsUploaderComponent {
  IconoirCloudUpload = IconoirCloudUpload;
  IconoirCheckCircle = IconoirCheckCircle;
  IconoirDownload = IconoirDownload;
  IconoirSearch = IconoirSearch;
  IconoirWarningCircle = IconoirWarningCircle;
  IconoirMoreHoriz = IconoirMoreHoriz;

  MAX_FILE_SIZE: number = 10 * 1024 * 1024; // 10MB

  @ViewChildren('fileInput') fileInputs!: QueryList<ElementRef<HTMLInputElement>>;
  @Output() filesEmitter = new EventEmitter<any[]>();
  files: any[] = [];

  documents: any[] = [
    {
      documentName: "Current Year Financial Statements", 
      documentNumber: 1,
      uploadDate: "",
      file: undefined,
      hasError: false,
      errorMessage: "",
      succeded: false,
    },
    // {
    //   documentName: "Prior Year Financial Statements", 
    //   documentNumber: 2, 
    //   uploadDate: "",
    //   file: undefined,
    //   hasError: false,
    //   errorMessage: "",
    //   succeded: false,
    // },
    {
      documentName: "Current Year Tax Computation", 
      documentNumber: 3,
      uploadDate: "",
      file: undefined,
      hasError: false,
      errorMessage: "",
      succeded: false,
    },
    {
      documentName: "Prior Year Tax Computation", 
      documentNumber: 4,
      uploadDate: "",
      file: undefined,
      hasError: false,
      errorMessage: "",
      succeded: false,
    },
  ];

  constructor(
    private toastService: ToastService
  ){}

  triggerFileUpload(index: number): void {
    const fileInput = this.fileInputs.toArray()[index];
    this.toastService.showToast(
      'warning', 
      'You will lost data from past run checks', 
      'motif-theme-light',
      "If you continue with this process of uploding new data, past data and previous runs or checks will be lost. You won't be able to return to the session where you last left off.", 
      'Ok', 
      false
    )
    // in this case we consider the .then() case because the toast needs user interaction
    .then((action) => {
      if(action && fileInput) {
        fileInput.nativeElement.click();
      }
    });
  }

  // Handle file selection
  onFileSelected(event: Event, document: any, index: number): void {
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files.length > 0) {
      const file = input.files[0]; 

      if (file.type === 'application/pdf' && file.size <= this.MAX_FILE_SIZE) {
        // console.log(`File at index ${index} is valid:`, file.name);

        document.hasError = false;
        document.succeded = true;
        document.file = file;
        document.uploadDate = this.getUploadDate();

        // Handle file upload logic here
        if(!this.files.includes(document)){
          this.files.push(document);
        }

        this.filesEmitter.emit(this.files);

      } else {
        console.error(`Invalid file at index ${index}. Please select a PDF under ${this.MAX_FILE_SIZE/(1024*1024)}MB.`);
        document.succeded = false;
        document.hasError = true;
        document.errorMessage = `Select a PDF file under ${this.MAX_FILE_SIZE/(1024*1024)}MB.`
      }
    }
  }

  getUploadDate(): string {
    const now = new Date();
    
    // Format the time to a 12-hour format with am/pm
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const period = hours >= 12 ? 'pm' : 'am';

    return `Today, at ${formattedHours}:${formattedMinutes}${period}`;
  }

  // Returns file name without the extension of the file
  getFileName(fileName: string): string{
    const lastDotIndex = fileName.lastIndexOf(".");
    
    // If there's no dot or it's the first character, return the file name as-is
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        return fileName;
    }
    
    // Return the file name without the extension
    return fileName.substring(0, lastDotIndex);
  }

  viewPdf(file: File){
    if (!file || file.type !== 'application/pdf') {
      this.toastService.showToast("error", "Invalid file. Please provide a valid PDF file.");
      return;
    }
  
    // Create a temporary URL for the PDF file
    const fileURL = URL.createObjectURL(file);
  
    // Open the URL in a new browser tab
    window.open(fileURL, '_blank');
  }

  downloadDocument(file: File){
    if (!file) {
      this.toastService.showToast("error", "No file to download");
      return;
    }
    const fileURL = URL.createObjectURL(file);
    const anchor = document.createElement('a');
    anchor.href = fileURL;
    anchor.download = file.name || 'download.pdf';
    anchor.click();
    URL.revokeObjectURL(fileURL);
  }

}
