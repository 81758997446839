<div class="lead-container">
    <app-documents-uploader
        (filesEmitter)="getUploadedFiles($event)"
    ></app-documents-uploader>
    
    <div class="bg-image"></div>
    
    <app-agent-five-home-cards
        (changeToTabEmitter)="toggleToTabNumber($event)"
        [filesToRun]="filesToRun"
    ></app-agent-five-home-cards>
</div>


