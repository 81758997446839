<div class="checks-cards-container">
    @for (check of checksArray; track $index) {
        <div class="check-card">
            
            <p class="check-number-text">CHECK {{ check.checkNumber }}</p>
            <p class="check-name-text">{{ check.name }}</p>

            <p class="check-description-text">{{ check.description }}</p>

            <div class="buttons-container">
                <button motifButton color="primary" (click)="changeToTab(check.tabNumberOrder)" [disabled]="check.disabled">View last run</button>
                
                <button motifButton color="primary" (click)="runCheck(check.checkNumber)" [disabled]="check.runLoading">
                    @if(check.runLoading){
                        <motif-progress-bar
                            [circle]="true"
                            [showLabel]="true"
                            [customLabel]="true"
                        ></motif-progress-bar>
                    }
                    @else{
                        Run
                    }
                </button>
            </div>

            @if(check.errorMessage){
                <div class="error-message-container">
                    <motif-icon size="24" class="icon-error" [iconFunction]="IconoirWarningCircle"></motif-icon>
                    <p class="error-message">{{ check.errorMessage }}</p>
                </div>
            }
            
        </div>
    }
</div>

<div class="run-all-container">
    <button motifButton color="primary" (click)="runAllChecks()">Run All</button>
</div>
