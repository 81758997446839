function a({
  size: i = "16",
  fill: r = "currentColor",
  strokeWidth: c = "1",
  title: s = "",
  stroke: o = "none"
}) {
  const l = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 19C5.25 18.5858 5.58579 18.25 6 18.25L18 18.25C18.4142 18.25 18.75 18.5858 18.75 19C18.75 19.4142 18.4142 19.75 18 19.75L6 19.75C5.58579 19.75 5.25 19.4142 5.25 19Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M11.7497 4.25C11.4349 4.25 11.1492 4.42844 11.019 4.70636L6.32214 15.1491C6.1387 15.5406 6.31715 16.0021 6.72073 16.18C7.1243 16.3579 7.60017 16.1849 7.78362 15.7934L8.70897 13.819H14.791L15.7164 15.7934C15.8998 16.1849 16.3757 16.3579 16.7793 16.18C17.1828 16.0021 17.3613 15.5406 17.1779 15.1491L12.4804 4.70636C12.3502 4.42844 12.0645 4.25 11.7497 4.25ZM14.0613 12.262H9.43868L11.7497 6.90987L14.0613 12.262Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = l.documentElement;
  if (s) {
    const t = l.createElement("title");
    t.textContent = s, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = i;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", r), e.setAttribute("stroke", o), e.querySelectorAll("path, line, rect, circle").forEach(n => {
      n.setAttribute("fill", r), n.setAttribute("stroke-width", c), n.setAttribute("stroke", o);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirCustomTextColor };