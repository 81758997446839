function a({
  size: s = "16",
  fill: n = "currentColor",
  strokeWidth: d = "1",
  title: r = "",
  stroke: i = "none"
}) {
  const o = new DOMParser().parseFromString(`<ns0:svg xmlns:ns0="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 6C8.25 5.58579 8.58579 5.25 9 5.25L20 5.25C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75L9 6.75C8.58579 6.75 8.25 6.41421 8.25 6Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M5 3.25C5.41421 3.25 5.75 3.58579 5.75 4L5.75 8C5.75 8.41421 5.41421 8.75 5 8.75C4.58579 8.75 4.25 8.41421 4.25 8V4C4.25 3.58579 4.58579 3.25 5 3.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4 9.25L5.4 9.25C6.14558 9.25 6.75 9.85442 6.75 10.6V11.4C6.75 12.1456 6.14558 12.75 5.4 12.75H4.75V13.25H6C6.41421 13.25 6.75 13.5858 6.75 14C6.75 14.4142 6.41421 14.75 6 14.75H4.6C3.85442 14.75 3.25 14.1456 3.25 13.4L3.25 12.6C3.25 11.8544 3.85442 11.25 4.6 11.25H5.25L5.25 10.75L4 10.75C3.58579 10.75 3.25 10.4142 3.25 10C3.25 9.58579 3.58579 9.25 4 9.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M4 15.25L5.4 15.25C6.14558 15.25 6.75 15.8544 6.75 16.6L6.75 19.4C6.75 20.1456 6.14558 20.75 5.4 20.75H4C3.58579 20.75 3.25 20.4142 3.25 20C3.25 19.5858 3.58579 19.25 4 19.25H5.25L5.25 16.75L4 16.75C3.58579 16.75 3.25 16.4142 3.25 16C3.25 15.5858 3.58579 15.25 4 15.25Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M6 18.75H4C3.58579 18.75 3.25 18.4142 3.25 18C3.25 17.5858 3.58579 17.25 4 17.25H6C6.41421 17.25 6.75 17.5858 6.75 18C6.75 18.4142 6.41421 18.75 6 18.75Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 12C8.25 11.5858 8.58579 11.25 9 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L9 12.75C8.58579 12.75 8.25 12.4142 8.25 12Z" fill="black" />
<ns0:path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 18C8.25 17.5858 8.58579 17.25 9 17.25L20 17.25C20.4142 17.25 20.75 17.5858 20.75 18C20.75 18.4142 20.4142 18.75 20 18.75L9 18.75C8.58579 18.75 8.25 18.4142 8.25 18Z" fill="black" />
</ns0:svg>`, "image/svg+xml"),
    e = o.documentElement;
  if (r) {
    const t = o.createElement("title");
    t.textContent = r, e.prepend(t);
  }
  if (e instanceof SVGElement) {
    const t = s;
    return e.setAttribute("width", t), e.setAttribute("height", t), e.setAttribute("fill", n), e.setAttribute("stroke", i), e.querySelectorAll("path, line, rect, circle").forEach(l => {
      l.setAttribute("fill", n), l.setAttribute("stroke-width", d), l.setAttribute("stroke", i);
    }), e;
  }
  throw new Error("Failed to create SVG element.");
}
export { a as IconoirNumberedListLeft };