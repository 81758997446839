<div class="toast warning">
    <motif-icon 
        [iconFunction]="IconoirWhiteFlag" 
        class="flag-icon"
        size="24" 
    ></motif-icon>
    
    <div>
        <p class="toast-title">
            We have identified {{ flaggedItems.length }} out of {{ fullData.length }} items that require your attention
        </p>
        
        <p class="toast-text">
            Please review the AI analysis below
        </p>
    </div>
    
    <motif-icon 
        [iconFunction]="IconoirXmark" 
        class="x-mark"
        size="24" 
        (click)="close()"
    ></motif-icon>
</div>